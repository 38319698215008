import { useState } from 'react'
import { useAxios } from './hooks/useAxios'
import { useInactiveListener } from './hooks/useInactiveListener'
import { getUser, useAuth } from './context/authContext'
import ConnectModal from './components/Modal/Connect'
// view/theme logic is in routes
import MyRouts from './routers/routes'

import './App.scss'

function App() {
  const [connectModalOpen, setConnectModalOpen] = useState(false)
  const { state } = useAuth()

  const connectAccount = (event: Event | any) => {
    if (event && 'preventDefault' in event) event.preventDefault()
    // if (typeof event === 'function') event()

    setConnectModalOpen(true)
  }

  useAxios()
  useInactiveListener()

  return (
    <div>
      <MyRouts connectAccount={connectAccount} getUser={getUser} user={state.user} />

      <ConnectModal connectModalOpen={connectModalOpen} setConnectModalOpen={setConnectModalOpen} />
    </div>
  )
}

export default App
