import { SetStateAction, useEffect, useState } from 'react'
import axios from 'axios'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import AuthorProfileHeader from './AuthorProfileHeader'
import NFT from '../Item/NftCard'
import TransferModal from '../Modal/Transfer'
import { useAuth } from '../../context/authContext'

import './profile.scss'

const AuthorProfile = (props: any) => {
  const { address } = useParams<{ address?: string }>()
  const { search } = useLocation()
  const history = useHistory()

  const [currentTab, setCurrentTab] = useState(new URLSearchParams(search)?.get('tab') ?? 'sale')

  const [authorProfile, setAuthorProfile] = useState(null)
  const { state } = useAuth()
  const { user } = state

  const [saleItems, setSaleItems] = useState([])
  const [ownedItems, setOwnedItems] = useState([])
  const [createdItems, setCreatedItems] = useState([])
  const [pageSale, setPageSale] = useState(1)
  const [pageOwned, setPageOwned] = useState(1)
  const [pageCreated, setPageCreated] = useState(1)
  const [noItems, setNoItems] = useState(false)
  const [loading, setLoading] = useState(false)
  const [initialItemsLoaded, setInitialItemsLoaded] = useState(true)
  const [itemTransferred, setItemTransferred] = useState(false)

  const tabs = [
    {
      id: 'sale',
      label: 'On Sale'
    },
    {
      id: 'owned',
      label: 'Owned'
    },
    {
      id: 'created',
      label: 'Created'
    },
  ]

  useEffect(() => {
    axios.get(`/user/${address || ''}`)
      .then(res => {
        const user = res.data.user
        setAuthorProfile({ ...user, xLink: user.xLink || user?.twitterLink })
      })
  }, [address, user])

  useEffect(() => {
    const pageName = new URLSearchParams(search)?.get('tab') ?? 'sale'

    if (!address) return

    let page = 1
    if (pageName === 'owned') page = pageOwned
    else if (pageName === 'created') page = pageCreated
    else page = pageSale

    const fetchItems = (name: string, page: number) => {
      let query = '/author-items/?'
      switch (name) {
        case 'created':
          query += `type=created&address=${address}&page=${page}`
          break
        case 'owned':
          query += `type=owned&address=${address}&page=${page}`
          break
        case 'sale':
        default:
          query += `type=sale&address=${address}&page=${page}&sale=true`
          break
      }
      setLoading(true)

      axios.get(query)
        .then(res => {
          if (name === 'owned') {
            initialItemsLoaded ? setOwnedItems(res.data.items) : setOwnedItems(ownedItems.concat(res.data.items))
          } else if (name === 'created') {
            initialItemsLoaded ? setCreatedItems(res.data.items) : setCreatedItems(createdItems.concat(res.data.items))
          } else {
            initialItemsLoaded ? setSaleItems(res.data.items) : setSaleItems(saleItems.concat(res.data.items))
          }
          if (res.data.items.length === 0 || res.data.final) setNoItems(true)

          setLoading(false)

        }).catch(err => {
          setLoading(false)
          if (name === 'owned') setOwnedItems([...ownedItems])
          else if (name === 'created') setCreatedItems([...createdItems])
          else setSaleItems([...saleItems])
          setNoItems(true)
          console.error('Error fetching Author Items::', err)
        })
    }

    fetchItems(pageName, page)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, currentTab, pageSale, pageOwned, pageCreated, itemTransferred])

  useEffect(() => {
    if (itemTransferred) {
      setItemTransferred(false)
    }
  }, [itemTransferred])

  function loadMore() {
    if (!loading) {
      setInitialItemsLoaded(false)
      if (currentTab === 'owned') setPageOwned(pageOwned + 1)
      else if (currentTab === 'created') setPageCreated(pageCreated + 1)
      else setPageSale(pageSale + 1)
    }
  }

  const tabClick = (tabName: SetStateAction<string>) => {
    setInitialItemsLoaded(true)
    history.push({ search: `?tab=${tabName}` })
    setCurrentTab(tabName)

    setNoItems(false)
    if (tabName === 'owned') setPageOwned(1)
    else if (tabName === 'created') setPageCreated(1)
    else setPageSale(1)
  }

  return (
    <section className='author-profile'>
      <AuthorProfileHeader author={authorProfile} account={user} />

      <div className='container py-4'>
        <div className='row justify-content-center text-center mb-4'>
          <div className='col-12'>
            <div className='explore-menu btn-group btn-group-toggle flex-wrap justify-content-center text-center mt-4' data-toggle='buttons'>
              {tabs.map(tab => (
                <label
                  key={tab.id}
                  className={`btn d-table text-uppercase ${currentTab === tab.id ? 'active' : ''}`}
                  onClick={() => tabClick(tab.id)}
                >
                  <input type='radio' checked={currentTab === tab.id} className='explore-btn' />
                  <span>{tab.label}</span>
                </label>
              ))}
            </div>
          </div>
        </div>
        {
          currentTab === 'owned'
            ? !ownedItems.length && !loading
              ? <p style={{ textAlign: 'center' }}>Items not found</p>
              : <div className='grid items explore-items' id='account_owned'>
                {ownedItems.map((item, index) => (
                  <NFT {...props} item={item} action='owned' transfer={user?.address && user.address === address} key={index} displayPrice={true} />
                ))}
              </div>
            : currentTab === 'created'
              ? !createdItems.length && !loading
                ? <p style={{ textAlign: 'center' }}>Items not found</p>
                : <div className='grid items explore-items' id='account_created'>
                  {createdItems.map((item, index) => (
                    <NFT {...props} item={item} key={index} displayPrice={true} />
                  ))}
                </div>

              : !saleItems.length && !loading ? <p style={{ textAlign: 'center' }}>Items not found</p>
                : <div className='grid items explore-items' id='account_sale'>
                  {saleItems.map((item, index) => (
                    <NFT {...props} item={item} action='sale' displayPrice={true} key={index} />
                  ))}
                </div>
        }

        <div className='load-more' style={{ display: noItems ? 'none' : '', textAlign: 'center' }}>
          <button onClick={() => loadMore()} className='btn branded mt-3 mt-sm-4'>
            {loading ? 'Loading...' : 'Load more'}
          </button>
        </div>
      </div>

      <TransferModal afterTransferCallback={() => setItemTransferred(true)} />
    </section>
  )
}

export default AuthorProfile
