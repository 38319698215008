import axios from 'axios'
import { useAuth } from '../context/authContext'

import { PROXY_HOST } from '../utils/constants'

export function useAxios() {
  const { state, dispatch } = useAuth()
  const { token } = state

  if (PROXY_HOST) {
    axios.defaults.baseURL = PROXY_HOST
  }

  axios.interceptors.request.use(request => {
    if (token && !request.headers.Authorization) {
      request.headers.Authorization = `Bearer ${token}`
    }

    if (window.location.pathname.startsWith('/admin')) request.timeout = 3600000 // Very long timeout for admin operations
    else request.timeout = 120000

    return request
  }, error => {
    console.error('Error in request interceptor:', error)
    throw error
  })

  axios.interceptors.response.use(response => {
    return Promise.resolve(response)
  }, error => {
    console.error('error catching: ', error)
    const response = error?.response
    const status = response?.status

    if (status && status === 401) {
      dispatch({ type: 'LOGOUT' })
    }
    return Promise.reject(error)
  })
}
