import { Link } from 'react-router-dom'

const NotFound = () => {
  return <div className='container text-center my-4'>
    <h1>imagiNation Market</h1>
    <h2>Page Not Found!</h2>

    <Link to="/explore/listed-items" className='btn branded'>Browse Sales</Link>
  </div>
}

export default NotFound
