import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
import './modals.scss'
import { Close } from '@styled-icons/material/Close'

const XShareModal = props => {
  const [hasData, setHasData] = useState(false)
  const [shareXMainText, setShareXMainText] = useState('')

  let { name, mainText, tweetText } = props?.data

  useEffect(() => {
    if (mainText || tweetText) {
      setShareXMainText(() => mainText)
      setHasData(true)
    }
  }, [mainText, tweetText])

  function closeXShare() {
    setHasData(false)
    mainText = null
    tweetText = null
  }

  return (
    <Modal
      className='content'
      isOpen={hasData}
      ariaHideApp={false}
      onRequestClose={() => closeXShare()}
    >
      <header>
        <h3>
          Share {name} on <span className="d-none">X</span><svg
            style={{ width: '35px', height: '100%' }}
            width="1200" height="1227" viewBox="0 0 1200 1227" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z" fill="#000" />
          </svg>
        </h3>
        <Close size={32} onClick={() => closeXShare()} style={{ color: 'red' }} className='close' />
      </header>

      {shareXMainText}

      <div className="ctas">
        <a href={`https://x.com/intent/tweet?url=${window.location.href}&text=${encodeURIComponent(tweetText) ?? ''}`} target='_blank' className='btn btn-info' rel="noopener">Share on X</a>
        <button className='btn btn-danger' onClick={() => closeXShare()}>Close</button>
      </div>
    </Modal>
  )
}

export default XShareModal
