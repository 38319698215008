import SaveNft from '../Item/Save'
import StarTwoToneIcon from '@mui/icons-material/StarTwoTone'

import './breadcrumb.scss'

const Breadcrumb = (props) => {
  const { featured, itemData, short } = props

  return (
    <>
      <section className={`breadcrumb-area overlay-dark${short ? ' short' : ''}`}>
        <div className='container'>
          <div className='breadcrumb-content text-center'>
            <h1 className='m-0'>{props.title}</h1>

            {itemData && <SaveNft {...props} data={itemData} />}
          </div>
        </div>
      </section>
    </>
  )
}

export default Breadcrumb
