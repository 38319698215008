import React, { Component } from 'react'
import Breadcrumb from '../components/Breadcrumb/Breadcrumb'
import Activities from '../components/Activity/Activity'

class Activity extends Component {
  render() {
    return (
      <div className='main'>
        <Breadcrumb title='Activity' subpage='Pages' page='Activity' short={true} />

        <div className='intro mt-2 mx-auto container relative'>
          <div className='intro-content'>
            <span>CREATIVE</span>
            <h2 className='mt-1 mb-0'>Activities</h2>
          </div>
        </div>

        <Activities />
      </div>
    )
  }
}

export default Activity
