import { Link } from 'react-router-dom'

import HeroSpotlight from './Spotlight'

import './hero.scss'

const Hero = ({ page }) => {
  return (
    <section className={`hero-section${page ? ` ${page}` : ''}`}>
      <div className='container'>
        <div className="content">
          <img className='symbol' src="/imagiNation-symbol.svg" alt='imagiNation Marketplace - Symbol only Logo' />
          <h1>A world of creativity at your fingertips</h1>
          <h2>Unleash Your Imagination!</h2>
          <h3><Link to='/search'>Create, Buy &amp; Sell Digital Collectibles</Link></h3>
        </div>
      </div>

      <HeroSpotlight />
    </section>
  )
}

export default Hero
