import type { Address, Cell, Hash } from '@ckb-lumos/lumos'

import axios from 'axios'
import {
  helpers,
} from '@ckb-lumos/lumos'
import { indexer } from '@ckb-lumos/base'
import {
  getDexLockScript,
  OrderArgs
} from '@nervina-labs/ckb-dex'

import {
  ACTIVITY_CONSTANTS,
  CHAIN_KEYS,
  HAS_PRIVILEGES,
  NETWORK,
  NFT_STANDARDS,
} from '../constants'
import { getSporeListPackagePrice } from './CkbMarket'
import CkbController from './CkbController'

class CkbSync {
  static #instance: CkbSync

  private constructor() { }

  static getInstance(): CkbSync {
    if (!CkbSync.#instance) {
      CkbSync.#instance = new CkbSync()
    }

    return CkbSync.#instance
  }

  public async runSyncAll(user: string) {
    if (!HAS_PRIVILEGES.includes(user)) return


  }

  public async runMarketSync() {
    console.info('runMarketSync')

    let itemData
    try {
      itemData = await this.syncActiveMarketCells()
    } catch (error) {
      throw new Error('Error syncing Active Market Cells')
    }

    try {
      await this.iterateResponseAddActivities(itemData, /*forceSync*/)
    } catch (error) {
      throw new Error('[runMarketSync] Error looping Market Interactions')
    }
  }

  public async runSporeSync(tokenIds: Hash, clusterId?: Hash, forceSync = false) {
    console.info('runSporeSync')

    const tokenIdsCheck = tokenIds.split(',')
      .map((address: string) => address.trim())
      .filter((address: string) => !!address)

    let syncData = []

    for await (const tokenId of tokenIdsCheck) {
      try {
        const { data } = await axios.post(`/sync/CKB/item/${clusterId || '0x0'}/${tokenId}`, {
          standard: NFT_STANDARDS.spore,
          forceSync
        })

        syncData = data?.synced ?? []
      } catch (error) {
        console.error('[runSporeSync] Error syncing Spore by ID!')
      }

      try {
        await this.iterateResponseAddActivities([...syncData], forceSync)
      } catch (error) {
        console.error('[runSporeSync] Error adding cell Activities!')
      }
    }
  }
  public async runUserSync(address: string, forceSync = false) {
    // back-end currently doesn't support DEX SDK
    // So Market owned Cells are synced separate for the time being
    console.info('runUserSync')

    let itemData = []
    let syncData

    try {
      itemData = await this.syncActiveMarketCells(
        address,
        true
      )
    } catch (error) {
      console.error('Error syncing Active Market Cells')
    }

    try {
      const { data } = await axios.post('/sync/CKB/all', {
        standard: NFT_STANDARDS.spore,
        contractAddresses: [],
        address,
        forceSync
      })

      syncData = data?.synced ?? []
    } catch (error) {
      console.error('[runUserSync] Error looping Market Interactions')
    }

    try {
      await this.iterateResponseAddActivities([...syncData, ...itemData], forceSync)
    } catch (error) {
      console.error('[runUserSync] Error adding cell Activities!')
    }
  }

  public async runCotaSyncUser(address: string, forceSync = false) {
    console.info('Running CoTA by User Sync')

    try {
      // 'all' syncs Clusters & Spores owned by this user
      const { data } = await axios.post(`/sync/CKB/collection/`, {
        standard: NFT_STANDARDS.cota,
        address
      })

      console.info('Running CoTA Sync User response', data)

      const syncedData = data?.synced ?? []

      await this.iterateResponseAddActivities(syncedData, forceSync)
    } catch (error) {
      console.error('[runCotaSync]')
    }
  }

  public async runCotaSyncId(address: string, txHash: string, forceSync = false) {
    console.info('Running CoTA ID Sync')

    try {
      // 'all' syncs Clusters & Spores owned by this user
      const { data } = await axios.post(`/sync/CKB/collection/`, {
        standard: NFT_STANDARDS.cota,
        addresses: [address],
        txHashes: [txHash],
      })

      console.info('Running CoTA Sync Id response', data)

      const syncedData = data?.synced ?? []

      await this.iterateResponseAddActivities(syncedData, forceSync)
    } catch (error) {
      console.error('[runCotaSync]')
    }
  }

  private async iterateResponseAddActivities(data: any, forceSync = false) {
    try {
      const syncedData = data?.synced ?? data

      if (!syncedData) return

      for await (const itemData of syncedData) {
        const actions = itemData?.typeScriptActions

        if (!actions) continue

        let actionsCount = 0

        for await (const item of actions) {
          await this.addCellActivity(
            {
              ...item,
              tokenId: itemData.tokenId,
              collectionAddress: itemData.collectionAddress,
              marketLockHash: getDexLockScript(NETWORK === 'mainnet').codeHash,
              id: actionsCount,
            },
            forceSync
          )

          actionsCount += 1
        }
      }
    } catch (error) {
      throw new Error('Error adding cell activity from iterating sync response!')
    }
  }

  public async syncActiveMarketCells(
    hashToCheck?: Address,
    isUser?: boolean,
  ): Promise<any[]> {
    try {
      const marketLockHash = getDexLockScript(NETWORK === 'mainnet').codeHash

      if (!marketLockHash) throw new Error('Invalid Sync Market params')

      // let cells = await CkbController.getSpecificCells(typeScript?.codeHash, [typeScript.args])

      const order: 'asc' | 'desc' | undefined = 'desc'

      const cellQuery: Array<indexer.QueryOptions> = [{
        lock: {
          codeHash: marketLockHash,
          hashType: 'type',
          args: '0x'
        },
        order
      }]
      const cells: Cell[] = []

      let cellCheckCallback
      if (isUser && hashToCheck) {
        const userLock = CkbController.getLock(hashToCheck)
        const userLockArgs = userLock.args.slice(2)

        cellCheckCallback = (cell: Cell) => cell.cellOutput?.lock?.args.includes(userLockArgs)
      } else if (hashToCheck) {
        cellCheckCallback = (cell: Cell) => cell.cellOutput?.type?.args.includes(hashToCheck)
      } else {
        cellCheckCallback = (cell: Cell) => cell.cellOutput.lock.codeHash === marketLockHash
      }

      try {
        for await (const cell of CkbController.indexer.collector(cellQuery as any).collect()) {
          if (cellCheckCallback && cellCheckCallback(cell)) cells.push(cell)
        }
      } catch (error) {
        console.error('Error getting Cells::', error)
        throw new Error('Error getting Cells')
      }

      const itemData = []
      for await (const cell of cells) {
        if (!cell?.data || cell.data === '0x') continue

        const { data } = await axios.post('/add/item', {
          standard: NFT_STANDARDS.spore,
          typeScriptArgs: [],
          cell,
        })

        itemData.push(data?.synced)
      }

      return itemData
    } catch (error) {
      console.error('Error syncing Spores::', error)
      throw new Error('Error syncing Spores')
    }
  }

  public async addCellActivity(
    data: {
      lockArgs: Hash
      tokenId: string
      collectionAddress: string
      marketLockHash: string
      txHash: string
      id: number
      type: number
      owner: Address
      transferTo?: Address
    },
    forceSync = false) {
    try {
      const tokenId = data.tokenId
      const itemCollection = data?.collectionAddress ?? '0x0'
      const activityData = {
        ...data,
        itemCollection,
        amount: '1',
        to: data?.transferTo,
        interactingContractAddress: '',
        price: '',
        chain: CHAIN_KEYS.ckb,
      }
      const lockArgs = data?.lockArgs

      let orderArgs
      if (lockArgs && lockArgs?.length < 66) {
        console.info('Activity LockArgs not of Market Lock', data)
      } else if (lockArgs) {
        orderArgs = OrderArgs.fromHex(lockArgs)

        if (orderArgs) {
          if (data.type !== ACTIVITY_CONSTANTS.Mint && data.type !== ACTIVITY_CONSTANTS.Transfer) {
            activityData.interactingContractAddress = data.marketLockHash
            activityData.price = (orderArgs.totalValue + getSporeListPackagePrice(helpers.encodeToAddress(orderArgs.ownerLock))).toString() ?? ''
          }
        } else {
          console.error('Add Activity Order Args invalid:: ', data)
        }

        if (orderArgs?.ownerLock && helpers.encodeToAddress(orderArgs.ownerLock) !== data.owner) {
          throw new Error('Owner Data mismatch!')
        }
      }

      if (isNaN(data?.type) || data.type > Object.keys(ACTIVITY_CONSTANTS).length) throw new Error('Activity type not valid')

      // @TODO: Update to check if type should be Listing Cancelled
      await axios.post('/add/activity', {
        query: {
          tokenId,
          itemCollection,
          id: data.id
        },
        data: activityData,
        forceSync
      })
    } catch (error) {
      console.error('error', error)
      throw new Error('Error adding Market Activity!')
    }
  }
}

export default CkbSync.getInstance()
