import { useEffect, useMemo, useState } from 'react'

import { getMimeType, tryAlternateUri } from '../../utils/helpers'

function base64ToArrayBuffer(base64: string) {
  const binaryString = window.atob(base64)
  const len = binaryString.length
  const bytes = new Uint8Array(len)

  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i)
  }

  return bytes.buffer
}

const MediaWrapper = (props: { [key: string]: any }) => {
  const { alt, className, assetType, file, fileType, item, src, style } = props

  const [altChecked, setAlt] = useState('')
  const [recheckMediaCounter, setRecheckMediaCounter] = useState(0)

  useEffect(() => {
    if (alt && typeof alt === 'string') setAlt(alt)
    if (recheckMediaCounter === 0) setRecheckMediaCounter(1)
  }, [props])

  const mediaSource = useMemo(() => {

    if (file && !src.startsWith('ipfs://')) {
      let fileBuffer
      if (typeof file === 'string') {
        fileBuffer = base64ToArrayBuffer(file)

      } else {
        fileBuffer = new Uint8Array(file.data).buffer
      }

      return URL.createObjectURL(new Blob([fileBuffer], { type: fileType || 'image/*' }))
    } else if (Object.keys(item?.preview ?? {}).length > 0) {
      return 'dob'
    }

    if (typeof src === 'string') return tryAlternateUri(src, recheckMediaCounter)
    else return ''
  }, [recheckMediaCounter, props])

  const htmlElement = useMemo(() => {
    if (!mediaSource) return null

    const typeCheck: string = mediaSource === 'dob' ? 'dob' : assetType

    switch (typeCheck) {
      case 'video':
        return <video
          src={mediaSource}
          title={altChecked}
          autoPlay
          loop
          style={{ width: '100%', borderRadius: 10 }}
          onError={() => setRecheckMediaCounter(recheckMediaCounter + 1)}
          crossOrigin="anonymous"
        />
      // Keeping if using srcObject becomes beneficial
      // Current requires rewrapping
      // React & srcObject are not native to each other...
      // https://github.com/facebook/react/issues/11163
      // if (typeof mediaSource === 'string') {
      //   return <video
      //     src={mediaSource}
      //     title={altChecked}
      //     autoPlay
      //     loop
      //     style={{ width: '100%', borderRadius: 10 }}
      //     onError={() => setRecheckMediaCounter(recheckMediaCounter + 1)}
      //     crossOrigin="anonymous"
      //   />
      // } else {
      //   return <video
      //     title={altChecked}
      //     autoPlay
      //     loop
      //     style={{ width: '100%', borderRadius: 10 }}
      //     onError={() => setRecheckMediaCounter(recheckMediaCounter + 1)}
      //     crossOrigin="anonymous"
      //     // @ts-ignore
      //     srcObject={new Blob([mediaSource], { type: fileType })}
      //   />
      // }

      case 'audio':
        return <audio
          src={mediaSource}
          title={altChecked}
          autoPlay
          loop
          controls
          style={{ width: '100%', borderRadius: 10 }}
          onError={() => setRecheckMediaCounter(recheckMediaCounter + 1)}
          crossOrigin="anonymous"
        />

      case 'dob':
        return (
          <dl
            className='dobBg'
            style={{
              background: item?.preview?.['.bgcolor']?.length > 7 ? `linear-gradient${item.preview?.['.bgcolor'].slice(1)}` : item?.preview?.['.bgcolor'],
              color: item?.preview?.color,
            }}
          >
            {item?.traits && Object.entries(item.traits).map(([key, value], index) => (
              !key.startsWith('prev') && <div key={key} style={{ display: 'grid', alignContent: 'center', border: '1px solid rgba(0, 0, 0, 0.1)', padding: '4px', gap: '4px' }}>
                <dt style={{ margin: 0 }}>{key}</dt>
                <dd>{String(value)}</dd>
              </div>
            ))}
          </dl>
        )

      case 'image':
      default:

        return <img
          src={mediaSource}
          onError={(error) => {
            if (recheckMediaCounter < 6) setRecheckMediaCounter(recheckMediaCounter + 1)
          }}
          alt={altChecked}
          title={altChecked}
          style={style}
          className={`image ${className || ''}`.trimEnd()}
          loading="lazy"
          crossOrigin="anonymous"
        />
    }
  }, [mediaSource, altChecked])

  return htmlElement
}

export default MediaWrapper
