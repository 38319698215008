import { Address, helpers, HexString, OutPoint, Script } from '@ckb-lumos/lumos'

import {
  buildCancelTx,
  buildMakerTx,
  buildTakerTx,
  // Collector,
  calculateNFTMakerListPackage,
  CKBAsset,
  getDexLockScript,
  matchNftOrderCells,
  OrderArgs,
} from '@nervina-labs/ckb-dex'

import { blockchain, bytes } from '@ckb-lumos/lumos/codec'
import { signRawTransaction } from '@joyid/ckb'
import { BrowserProvider } from 'ethers'
import axios from 'axios'

import { ACTIVITY_CONSTANTS, APP_KEYS, CHAIN_KEYS, MIN_CKB_FEE, NETWORK } from '../constants'
import CkbController from './CkbController'
import { JoyIdConnector } from '../JoyIdConnector'
import { signLumosTx } from './CkbService'

export interface Fees {
  listing?: string
  itemRoyalty?: string
  collectionRoyalty?: string
  dev: string
}

export interface FeeAddressList {
  currentUser: Address
  itemCreator?: Address
  collectionCreator?: Address
  seller?: Address
}

export const getSporeListPackagePrice = (userAddress: Address) => {
  try {
    return calculateNFTMakerListPackage(userAddress)
  } catch (error) {
    console.error('Error getting List Package Price')
    return 0n
  }
}

export const listSpore = async (
  provider: JoyIdConnector | BrowserProvider,
  data: {
    activityCount: number,
    userAddress: string,
    tokenId: string,
    listPrice: BigInt,
    activeWallet: string,
    collectionAddress: string
    fees: Fees
  }) => {
  let joyID

  if ('account' in provider) {
    if (data.activeWallet === APP_KEYS.joyid && !provider?.account) throw new Error('JoyID Account not configured!')
    else if (provider?.account) joyID = { connectData: provider.account }
  }

  if (!joyID) throw new Error('JoyID not found!')

  const totalValue = data.listPrice.valueOf() - getSporeListPackagePrice(data.userAddress)
  if (totalValue < (MIN_CKB_FEE * 10 ** 8)) throw new Error('Total Value not high enough!')

  let listTxData
  try {
    listTxData = await buildMakerTx({
      collector: CkbController.collector,
      joyID,
      seller: data.userAddress,
      totalValue,
      assetType: bytes.hexify(blockchain.Script.pack(
        {
          ...CkbController.getSporeTypeScript(),
          args: data.tokenId
        }
      )),
      ckbAsset: CKBAsset.SPORE,
    })
  } catch (error) {
    throw new Error('Spore::Error Building TX')

  }
  const query = {
    itemCollection: data.collectionAddress,
    tokenId: data.tokenId,
    id: data.activityCount,
  }

  let signedTx
  try {
    if (data.activeWallet === APP_KEYS.joyid) {
      signedTx = await signRawTransaction(
        // @ts-ignore
        // helpers.createTransactionFromSkeleton(listTxData.rawTx),
        listTxData.rawTx,
        data.userAddress,
        {
          witnessIndex: listTxData.witnessIndex
        }
      )
    } else {
      provider = provider as BrowserProvider
      signedTx = await signLumosTx(
        await CkbController.prepareRawTransaction(listTxData.rawTx),
        helpers.parseAddress(data.userAddress),
        await provider.getSigner()
      )
    }
  } catch (error) {
    throw new Error('Spore::Error Submitting TX')
  }

  const txHash = await CkbController.sendTransaction(signedTx)
  const createdAt = Date.now()

  try {

    await axios.post('/add/activity', {
      query,
      data: {
        ...query,
        type: ACTIVITY_CONSTANTS.ListingCreated,
        amount: '1',
        owner: data.userAddress,
        createdAt,
        updatedAt: createdAt,
        interactingContractAddress: getDexLockScript(NETWORK === 'mainnet').codeHash,
        txHash,
        price: data.listPrice.toString(),
        chain: CHAIN_KEYS.ckb,
        fees: data?.fees
      },
      forceSync: false
    })

    const orderArgs = new OrderArgs(helpers.parseAddress(data.userAddress), 4, totalValue)
    const marketAddress = helpers.encodeToAddress({
      codeHash: getDexLockScript(NETWORK === 'mainnet').codeHash,
      hashType: 'type',
      args: orderArgs.toHex()
    })

    await axios.post(`/update/${query.itemCollection}/${query.tokenId}`, {
      newItemData: { owner: marketAddress }
    })
  } catch (error) {
    throw new Error('Spore::Error Syncing Data')
  }

  return txHash
}

export const buySpore = async (
  provider: JoyIdConnector | BrowserProvider,
  data: {
    userAddress: string
    tokenId: string
    listPrice: BigInt
    activeWallet: string
    collectionAddress: string
    outPoint: OutPoint
    fees: Fees
    itemCreator: string
    collectionCreator: string
    seller: string
    id: number
  }) => {
  try {
    let joyID

    if ('account' in provider) {
      if (data.activeWallet === APP_KEYS.joyid && !provider?.account) throw new Error('JoyID Account not configured!')
      else if (provider?.account) joyID = { connectData: provider.account }
    }

    if (!joyID) throw new Error('JoyID not found!')

    let rawTx, witnessIndex

    try {
      const makerObject = await buildTakerTx({
        collector: CkbController.collector,
        joyID,
        buyer: data.userAddress,
        orderOutPoints: [bytes.hexify(
          blockchain.OutPoint.pack(data.outPoint)
        )],
        ckbAsset: CKBAsset.SPORE
      })

      rawTx = makerObject.rawTx
      witnessIndex = makerObject.witnessIndex
    } catch (error) {

    }

    if (!rawTx) throw new Error('Error generating Tx!')

    const userLock = helpers.parseAddress(data.userAddress)

    let txSkeleton

    try {
      txSkeleton = CkbController.prepareTransaction(
        await CkbController.prepareRawTransaction(rawTx, data.activeWallet),
        data.activeWallet
      )
    } catch (error) {
      throw new Error('Error preparing transaction!')
    }

    try {
      txSkeleton = await CkbController.addFees(
        txSkeleton,
        { dev: getSporeListPackagePrice(data.userAddress).toString() },
        { currentUser: data.userAddress }
      )
    } catch (error) {
      throw new Error('Error with Controller')
    }

    let signedTx
    if (data.activeWallet === APP_KEYS.joyid) {
      signedTx = await signRawTransaction(
        // @ts-ignore
        helpers.createTransactionFromSkeleton(txSkeleton),
        // txSkeleton,
        data.userAddress,
        {
          witnessIndex
        }
      )
    } else {
      provider = provider as BrowserProvider
      signedTx = await signLumosTx(
        // await CkbController.prepareRawTransaction(txSkeleton),
        txSkeleton,
        userLock,
        await provider.getSigner()
      )
    }

    const txHash = await CkbController.sendTransaction(signedTx)

    try {
      await axios.post('/update/activity', {
        tokenId: data.tokenId,
        itemCollection: data.collectionAddress,
        id: data.id,
        type: ACTIVITY_CONSTANTS.ListingSold,
        updatedAt: Date.now(),
        txHash,
      })
    } catch (error) {
      console.error('Error updating Activity')
    }


    try {
      await axios.post(`/update/${data.collectionAddress}/${data.tokenId}`, {
        newItemData: { owner: data.userAddress }
      })
    } catch (error) {
      console.error('Error updating owner')
    }

    return true
  } catch (error) {
    throw new Error('Error buying Spore!')
  }
}

export const delistSpore = async (provider: JoyIdConnector | BrowserProvider, data: { userAddress: string, tokenId: string, activeWallet: string, collectionAddress: string, txHash: string, id: number }) => {
  try {
    let joyID

    if ('account' in provider) {
      if (data.activeWallet === APP_KEYS.joyid && !provider?.account) throw new Error('JoyID Account not configured!')
      else if (provider?.account) joyID = { connectData: provider.account }
    }

    if (!joyID) throw new Error('JoyID not found!')

    const { rawTx, witnessIndex } = await buildCancelTx({
      collector: CkbController.collector,
      joyID,
      seller: data.userAddress,
      orderOutPoints: [bytes.hexify(
        blockchain.OutPoint.pack({
          txHash: data.txHash,
          index: '0x0',
        })
      )],
      ckbAsset: CKBAsset.SPORE,
    })

    let signedTx
    if (data.activeWallet === APP_KEYS.joyid) {
      signedTx = await signRawTransaction(
        // @ts-ignore
        rawTx,
        data.userAddress,
        {
          witnessIndex
        }
      )
    } else {
      provider = provider as BrowserProvider
      signedTx = await signLumosTx(
        await CkbController.prepareRawTransaction(rawTx),
        helpers.parseAddress(data.userAddress),
        await provider.getSigner()
      )
    }

    const txHash = await CkbController.sendTransaction(signedTx)

    try {
      await axios.post('/update/activity', {
        tokenId: data.tokenId,
        itemCollection: data.collectionAddress,
        id: data.id,
        type: ACTIVITY_CONSTANTS.ListingSold,
        updatedAt: Date.now(),
        txHash,
      })
    } catch (error) {
      console.error('Error updating Activity')
    }

    await axios.post(`/update/${data.collectionAddress}/${data.tokenId}`, {
      newItemData: { owner: data.userAddress }
    })

    return true
  } catch (error) {
    console.error(error)
    throw new Error('Error delisting Spore!')
  }
}
