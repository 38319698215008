import styled from 'styled-components'
import { PlusCircle } from '@styled-icons/feather'

export const UploadField = styled.div`
`
export const UploadContainer = styled.div`
`
export const UploadCaption = styled.label`
    font-size: 0.9rem;
    color: #111;
`
export const label = styled.div`

`
export const ChooseFileBtn = styled.div`
  display: inline-block;
    position: relative;
    padding: 10px 20px;
    background: #7fd951;
    border-radius: 20px;
    margin-top: 20px;
    color: #111;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
`
export const FileInput = styled.input`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    cursor: pointer;
`
export const PreviewContainer = styled.div`

`
export const MediaContainer = styled.div`

`
export const ImagePreview = styled.img`
`
export const VideoPreview = styled.video`
  border-radius: 6px;
  max-width: 80%;
  max-height: 100%;
`
export const AudioPreview = styled.audio`
  border-radius: 6px;
  max-width: 80%;
  max-height: 100%;
`

export const CollectionIcon = styled.img`
    width: 48px;
    height: 48px;
    border-radius: 24px;
`
export const CollectionName = styled.div`
    // color: #1E2026;
    font-weight: bold;
    font-size: 16px;
    margin-top: 12px;
    text-align: center;
`
export const CollectionType = styled.div`
    color: grey;
    font-size: 14px;
`
export const CollectionPlusIcon = styled(PlusCircle)`
`
export const Collections = styled.div`
    display: flex;
    flex-flow: wrap;
`
export const SelectCollection = styled.div`
`
export const collection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 180px;
    height: 180px;
    border: solid 1px #cacaca;
    border-radius: 12px;
    margin: 8px;
    cursor: pointer;
    &.active {
        border: solid 1px #a754da;
        background: #fafafa;
    }
    &:hover {
        background: #eee;
        border: 1px solid #57048a;
    }
`
export const Field = styled.div`
    margin: 0.15rem 0.5rem 0.5rem 0.5rem;
`
export const Input = styled.input`
    width: 100%;
    border: unset;
    border-bottom: 1px solid rgb(234, 236, 239);
    font-size: 16px;
    padding: 8px;
    &:focus-visible{
        outline: unset;
    }
    &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`

export const Select = styled.select`
    background: #fff;
    width: 100%;
    border: unset;
    border-bottom: 1px solid rgb(234, 236, 239);
    font-size: 16px;
    padding: 8px;
    &:focus-visible{
        outline: unset;
    }
    &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`
export const ModalAction = styled.div`
  width: 100%;
  text-align: center;
  grid-column: 1/3;
`
export const ModalButton = styled.div`
     padding: 12px 40px;
    background: #e24717;
    font-size: 20px;
    color: white;
    border-radius: 24px;
    cursor: pointer;
`
export const ModalBody = styled.div`
  padding: 0.5rem 1rem;
`
export const ModalTitle = styled.h2`
  font-weight: bold;
  font-size: 2.5rem;
  margin: 0 0 1rem;
  color: rgb(0,127,0);
  text-align: center;
  position: relative;
`

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
`

// More Custom Styles
export const PropertyAction = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`
export const PropertyActionWrap = styled.div`
`
