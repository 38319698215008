import React, { Component } from 'react'
import SearchInput from '../Search/SearchInput'

const initData = {
  menuName: 'Search',
  menuIcon: 'far fa-times-circle icon-close',
  heading: 'Find your next NFT!',
  btnText: 'Search'
}

class ModalSearch extends Component {
  state = {}

  componentDidMount() {
    this.setState({
      ...initData
    })
  }

  render() {
    return (
      <div id="search-modal" className='modal fade p-0' style={{ zIndex: 1500 }}>
        <div className='modal-dialog dialog-animated'>
          <div className='modal-content h-100'>
            <div className='modal-header' data-dismiss='modal'>
              {this.state.menuName} <i className={this.state.menuIcon} />
            </div>
            <div className='modal-body'>
              <div className='col-12 align-self-center'>
                <div className='row'>
                  <div className='col-12 pb-3'>
                    <h2 className='search-title mt-0 mb-3'>{this.state.heading}</h2>
                  </div>
                </div>
                <div className="row">
                  <SearchInput />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ModalSearch
