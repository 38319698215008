import React, { useMemo, useState } from 'react'
import { IconButton, Snackbar } from '@mui/material'
import CreateCollection from './Collections'
import CreateItem from './Items'
import { CHAIN_KEYS } from '../../utils/constants'

import './create.scss'
import MediaWrapper from '../Media/MediaWrapper'
import DefaultBanner from '../../assets/img/hero.webp'
import Breadcrumb from '../Breadcrumb/Breadcrumb'

const Create = (props: {
  user: any
  connectAccount: React.MouseEventHandler<HTMLButtonElement> | undefined
}) => {
  const [snackBarMessage, setSnackBarMessage] = useState('')
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [selectedCollection, setSelectedCollection] = useState<{
    [chain: string]: { [key: string]: any }
  } | null>(null)
  const [chain, setChain] = useState(CHAIN_KEYS.ckb)

  const handleCloseDialog = (event: any, reason: string) => {
    if (reason === 'clickaway') return
    setOpenSnackbar(false)
  }

  const renderChainButtons = useMemo(
    () => Object.values(CHAIN_KEYS)
      .map(chainKey =>
        <button
          key={chainKey}
          className={`btn ${chain === chainKey ? 'btn-success active' : 'btn-bordered'}`}
          onClick={() => setChain(chainKey)}
        >{chainKey}</button>
      )
    , [chain])

  const bannerImageSrc = useMemo(
    () => {
      if (!selectedCollection || !selectedCollection[chain]?.bannerImage) return DefaultBanner

      return selectedCollection[chain].bannerImage
    }
    , [selectedCollection])

  return (
    !props?.user?.address
      ? <button className='connect-wallet mt-4' onClick={props.connectAccount}>Please Connect Wallet to login and view creation options.</button>
      : <section id='Create'>
        {selectedCollection
          ? <div className='banner'>
            <MediaWrapper src={bannerImageSrc} style={{ maxHeight: '200px', width: '100%', objectFit: 'cover' }} />
            <h1 className='text-center'>Create</h1>
          </div>
          : <Breadcrumb {...props} title='Create' />
        }


        <div className='container mt-4'>
          <div id='Chain'>
            {renderChainButtons}
          </div>

          <CreateCollection
            {...props}
            selectedCollection={selectedCollection}
            setSelectedCollection={setSelectedCollection}
            setSnackBarMessage={setSnackBarMessage}
            setOpenSnackbar={setOpenSnackbar}
            chain={chain}
          />

          <CreateItem
            {...props}
            selectedCollection={selectedCollection}
            setSnackBarMessage={setSnackBarMessage}
            setOpenSnackbar={setOpenSnackbar}
            chain={chain}
          />
        </div>


        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          open={openSnackbar}
          autoHideDuration={3000}
          onClose={handleCloseDialog}
          message={snackBarMessage}
          action={
            <>
              <IconButton size='small' aria-label='close' color='inherit' onClick={() => handleCloseDialog(null, '')}>
                <span className='close-icon'>&#9760;</span>
              </IconButton>
            </>
          }
        />
      </section>
  )
}

export default Create
