import axios from 'axios'
import React, { useState, useEffect, useMemo, useCallback } from 'react'
import Breadcrumb from '../components/Breadcrumb/Breadcrumb'
import { useHistory, useLocation } from 'react-router-dom'
import NFT from '../components/Item/NftCard'
import SearchSideBar from '../components/Search/Sidebar'
import SearchTopbar from '../components/Search/Topbar'

import '../components/Search/search.scss'
import { debounce } from 'lodash'

const SearchPage = (props: { [key: string]: any }) => {
  const location = useLocation()
  const history = useHistory()

  const [items, setItems] = useState([])
  const [noItems, setNoItems] = useState(true)
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [pageLimit, setPageLimit] = useState(6)
  const [filteredSearch, setFilteredSearch] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [formValues, setFormValues] = useState<{ [key: string]: any } | null>(null)

  const debouncedSearch = useCallback(
    debounce((term) => {
      if (history.location.search.slice(1) === term) return

      setLoading(true)
      history.push(`/search?${decodeURIComponent(term)}`)
    }, 900), [])

  const regularSearch = useCallback((term: string) => {
    history.push(`/search?${decodeURIComponent(term)}`)
  }, [])

  const onFormChange = (name: string, value: string, event?: Event) => {
    let newSearchQuery = new URLSearchParams(location.search)
    let subName = ''

    if (name.includes('marketStatus')) {
      subName = name.split('-')[1]
      name = 'marketStatus'
    }

    setPage(1)

    switch (name) {
      case 'marketStatus':
        const currentKeys = newSearchQuery?.get('marketStatus')
        let statusKeys = currentKeys ? [...currentKeys.split(',')] : []

        if (!!value) {
          statusKeys.push(subName)
        } else {
          statusKeys = statusKeys.filter((key) => key !== subName)
        }

        const statusKeyArray = statusKeys.join(',')
        if (!!statusKeys.length) {
          newSearchQuery.set('marketStatus', statusKeyArray)
          setFormValues((prevValues) => ({ ...prevValues, [name]: statusKeyArray }))
        } else {
          newSearchQuery.delete('marketStatus')
          setFormValues((prevValues) => {
            if (prevValues) {
              delete prevValues.marketStatus
            }
            return { ...prevValues }
          })
        }

        regularSearch(newSearchQuery.toString())
        setFilteredSearch(true)
        break

      case 'mintedDateStart':
      case 'mintedDateEnd':
      case 'lastActivityDateStart':
      case 'lastActivityDateEnd':
      case 'minPrice':
      case 'maxPrice':
      case 'searchTerm':
      default:
        if (!value || Number(value) === 0 || String(value) === '') {
          newSearchQuery.delete(name)
          setFormValues((prevValues) => {
            if (prevValues) {
              delete prevValues[name]
            }
            return { ...prevValues }
          })
        } else {
          newSearchQuery.set(name, value)
          setFormValues((prevValues) => ({ ...prevValues, [name]: value }))
        }

        debouncedSearch(newSearchQuery.toString())
        setFilteredSearch(true)
        break
    }
  }

  const searchQuery = useMemo(() => {
    return new URLSearchParams(location?.search).toString()
  }, [location?.search])

  useEffect(() => {
    if (!location?.search) return

    const formValuesHolder: { [key: string]: string } = {
      display: 'grid',
      sortDirection: 'descending',
      sortBy: 'createdAt',
    }

    for (const queryParam of new URLSearchParams(location.search).entries()) {
      formValuesHolder[queryParam[0]] = queryParam[1]
    }

    setFormValues((prevValues) => ({ ...prevValues, ...formValuesHolder }))
  }, [location.search])

  useEffect(() => {
    if (!isFetching) {
      fetchItems()
    }

    return () => setIsFetching(false)
  }, [page, searchQuery])

  async function fetchItems() {
    setIsFetching(true)

    try {
      const isFiltered = filteredSearch ? '&isFiltered=true' : ''
      const result = await axios.get(`/search/?${searchQuery}&page=${page}${isFiltered}`)

      if (!result.data.items.length) {
        setNoItems(true)
        setItems([])
        return
      }

      if (page === 1) setItems(result.data.items)
      else setItems(items.concat(result.data.items))

      if (result?.data?.count < pageLimit) {
        setNoItems(true)
      } else {
        setNoItems(false)
      }
    } catch (error) {
      console.error('Error fetching searched-for items', error)
      setItems([...items])
    } finally {
      setIsFetching(false)
      setLoading(false)
    }
  }

  function loadMore() {
    if (!loading) {
      setLoading(true)
      setPage(page + 1)
    }
  }

  return (
    <>
      <Breadcrumb title='Search' subpage='Pages' page='Search' />
      <article id='Search'>
        <div className='search-wrapper'>
          <details className="sort" open={window.innerWidth >= 767}>
            <summary>Sorting Filters <i className='fa-solid fa-caret-right arrow' /></summary>

            <SearchTopbar onFormChange={onFormChange} formValues={formValues} />
          </details>

          <details className="refine" open={window.innerWidth >= 767}>
            <summary>Refine Search <i className='fa-solid fa-caret-right arrow' /></summary>

            <SearchSideBar onFormChange={onFormChange} formValues={formValues} />
          </details>

          <div className={`search-grid ${items?.length > 0 ? 'items' : ''}`.trimEnd()}>
            {loading && page < 2 ? <p className='text-center no-items'><strong>Loading Content...</strong></p> :
              items && items?.length > 0 ?
                items.map((item, index) => (
                  <NFT {...props} item={item} key={index} displayPrice={true} />
                ))
                : !loading && !isFetching ? <p className='text-center no-items'><strong>No Items Found!</strong></p> : <p className='text-center no-items'><strong>Loading...</strong></p>}
          </div>
        </div>

        <div className='load-more' style={{ display: noItems ? 'none' : '', textAlign: 'center' }}>
          <button onClick={() => loadMore()} className='btn branded mt-3 mt-sm-4'>
            {loading || isFetching ? 'Loading...' : 'Load more'}
          </button>
        </div>
      </article>
    </>
  )
}

export default SearchPage
