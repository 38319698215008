"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.REEM_CLUSTER_ID = exports.NERVAPE_CLUSTER_ID = exports.NERVAPE_GEAR_CLUSTER_IDS = void 0;
exports.NERVAPE_GEAR_CLUSTER_IDS = {
    mainnet: [
        '0xf2d14e1d6e57faaea4140ac52724a8e1e8e7d8a254e49b4aa2eebb9a051a2680', // Ears
        '0x1a2ca0c10daadb9f9e087283fecdc72c136dfd0d050d8041f2d4a4cf012d5149', // Head wear
        '0x95a56c18700cbc3f4e98895fe7063094f592fd89964d49ba5a5c1da1f66b7169', // Mask
        '0x482cc386a3d6ed2a5f645a31d8198cbb3be2b7151d53c80468ecbc2c97d6da60', // Eye wear
        '0xbe655dc598f3cdc23ce935e76d827288e460e3cf62f8bc9b2f5c59b47a4750da', // Mouth
        '0x57de7ae055fa69b9bcf949ffeda3f73495f63fd88252c2e5812acfbac100225a', // Accessory
        '0x29c1012f72d916461c9dabcca1315b9e6082ed179210214c1fa335ae66719818', // Upper Body
        '0xc62fc8d75b8fb5b3f7c65f4dfc4063488d02f2480366c5010825b90c363c1a5d', // Handheld
        '0x4321a4e5201ad5abba69962a3aec78ff5c1af489af7db28614314258850f7ccf', // Lower Body
        '0x6e6c9500958d3962d880a50ba792d3a3f408533e10666751a141148a6bf01938', // Tattoo
    ],
    testnet: [
        '0xd187c5b0636d4b10dcf9cc5ea9d4bf9ce77b885c8bf5ea62f973cb3aa5dc70df', // Head
        '0x0717800213116ee49f69a2ed2ddca1f5954ba4c506b2edd5b5a6d5bb5f4a6b7a', // Eye
        '0x3a354ccd0bcb5467c0aa9fa5302ab023fe0490e57d7212e5dc0d49626068341f', // Accessory
        '0xabc7ce87640ef558658cc14be037432e22c10eb4d9bb147ddd9b08b53a56e516', // Handheld
        '0x99f2d34427694b1188d7ba462bd43e82c15f3a8238adab3bf5863c9bf97c9373', // Lower Body
        // '0xa3f74f80c5fcb3434ae5bbb97e025074d05cf554f3ba1787cf599e0bf20aa595', // Second Handheld
    ]
};
exports.NERVAPE_CLUSTER_ID = {
    mainnet: '0xd5852c19fa4fa394d64915cafe026cdeb702ce53cf2b839c6ace501e8dead41c'
};
exports.REEM_CLUSTER_ID = {
    mainnet: '0x11b5087a33102ace13815b598754c5896c443fa00a949845e86fbe0df1ca36cd'
};
