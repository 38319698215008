import Hero from '../components/Hero/Hero'
import Auctions from '../components/Auctions/Auctions'
import Collections from '../components/Collections/List'
import Explore from '../components/Explore/Explore'

const Home = (props) => {
  return (
    <article style={{ display: 'grid', paddingTop: 0 }}>
      <Hero />
      <Collections {...props} isHome={true} />
      <Explore {...props} isHome={true} />
      <Auctions {...props} isHome={true} />
    </article>
  )
}

export default Home
