import { Fragment, useState, useEffect } from 'react'
import { Dialog, Button } from '@mui/material'

import {
  CONNECTOR_STORAGE_KEY,
  connectToProvider,
  connectors,
  IConnector,
  tryConnectToProvider
} from '../../utils/connectors'
import { useAuth } from '../../context/authContext'
import { ReactComponent as CKBullIcon } from '../../assets/img/wallets/ckbull.svg'

const ConnectModal = (props: {
  connectModalOpen: boolean
  setConnectModalOpen: any
}) => {
  const { connectModalOpen, setConnectModalOpen } = props
  const { dispatch } = useAuth()

  const [isError, setIsError] = useState(false)
  const [networkError, setNetworkError] = useState('')

  const closeErrorModal = () => {
    window.localStorage.removeItem(CONNECTOR_STORAGE_KEY)
    setIsError(false)
  }

  useEffect(() => {
    const checkPreviousConnection = window.localStorage.getItem(CONNECTOR_STORAGE_KEY)
    const connector = connectors.find(connector => connector.key === checkPreviousConnection)

    if (!connector) return
    tryConnectToProvider(connector, dispatch)
  }, [])

  return (<Dialog open={connectModalOpen}>
    <Dialog
      className='web3-modal' maxWidth='xs'
      // disableBackdropClick
      // disableEscapeKeyDown
      open={connectModalOpen}
      onClose={(event, reason) => { if (reason && reason === 'backdropClick') return; setConnectModalOpen(false); setIsError(false) }}
      aria-labelledby='simple-modal-title'
      aria-describedby='simple-modal-description'
    >
      <div className='content'>
        {isError ? <>
          <p>{networkError}</p>
          <Button
            onClick={() => closeErrorModal()}
            variant='contained'
            color='error'
            size='medium'
            key='close-web3-modal'
          >Close</Button>
        </>
          : <>
            {connectors.map((entry: IConnector, index) => (
              <Fragment key={entry.key}>
                <Button

                  variant='outlined'
                  color='secondary'
                  onClick={async () => {
                    try {
                      await connectToProvider(entry, dispatch)
                    } catch (error) {
                      setNetworkError(`Error connecting to wallet:: ${error}`)
                    } finally {
                      setConnectModalOpen(false)
                    }
                  }}
                  className={`connect-button textPrimary ${entry.key.toLowerCase()}`}
                  sx={{ color: 'red', marginBottom: '10px', justifyContent: 'space-between' }}
                  // @ts-ignore
                  endIcon={<entry.icon />}
                  id={`wallet-connect--${entry.key.toLocaleLowerCase().split(' ').join('-')}`}
                  title={`Connect using ${entry.key} Wallet on imagiNation Market`}
                >
                  <span className='sr-only'>{entry.key}</span>
                </Button>

                {index === 0 && <hr />}
              </Fragment>
            ))}

            <Button
              className='connect-button ckbull'
              variant='outlined'
              endIcon={<CKBullIcon />}
              title='CKBull - Coming Soon too imagiNation!'
              key='CKBull'
            >
              <span className='sr-only'>CKBull</span>
            </Button>

            <Button
              onClick={() => { setConnectModalOpen(false) }}
              variant='contained'
              color='error'
              size='small'
              sx={{ marginTop: '1rem' }}
              key='close-web3-modal'
            >Close</Button>
          </>}
      </div>
    </Dialog>
  </Dialog>)
}

export default ConnectModal
