import React from 'react'

import Breadcrumb from '../components/Breadcrumb/Breadcrumb'
import Explore from '../components/Explore/Explore'

const ListedItems = (props) => {
  return (
    <>
      <Breadcrumb {...props} title='Explore' subpage='Explore' page='Explore Style 3' />
      <Explore />
    </>
  )
}

export default ListedItems
