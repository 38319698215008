import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { SvgIcon } from '@mui/material'

class Work extends Component {
  render() {
    return (
      <article className='work-area'>
        <div className='container py-3'>
          <div className='intro'>
            <div className='intro-content'>
              <span>How It Works</span>
              <h3 className='mt-1 mb-0'>Unleash Your Creativity with Digital Assets</h3>
            </div>
          </div>

          <div className='items'>
            <div className='single-work'>
              {/* Custom Wallet Icon */}
              <SvgIcon sx={{ fontSize: 50 }} className='icon'>
                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3 0C1.34531 0 0 1.5375 0 3.42857V20.5714C0 22.4625 1.34531 24 3 24H21C22.6547 24 24 22.4625 24 20.5714V8.57143C24 6.68036 22.6547 5.14286 21 5.14286H3.75C3.3375 5.14286 3 4.75714 3 4.28571C3 3.81429 3.3375 3.42857 3.75 3.42857H21C21.8297 3.42857 22.5 2.6625 22.5 1.71429C22.5 0.766071 21.8297 0 21 0H12H3ZM19.5 12.8571C19.8978 12.8571 20.2794 13.0378 20.5607 13.3592C20.842 13.6807 21 14.1168 21 14.5714C21 15.0261 20.842 15.4621 20.5607 15.7836C20.2794 16.1051 19.8978 16.2857 19.5 16.2857C19.1022 16.2857 18.7206 16.1051 18.4393 15.7836C18.158 15.4621 18 15.0261 18 14.5714C18 14.1168 18.158 13.6807 18.4393 13.3592C18.7206 13.0378 19.1022 12.8571 19.5 12.8571Z" fill="white" />
                  <path d="M13.3033 14.8606C14.3764 13.6593 14.3764 11.7138 13.3033 10.5124C12.3537 9.44933 10.8571 9.31113 9.76498 10.185L9.73459 10.2084C9.4611 10.4274 9.39842 10.8526 9.59404 11.1567C9.78967 11.4607 10.1695 11.533 10.4411 11.314L10.4715 11.2906C11.0812 10.8037 11.915 10.8803 12.443 11.4735C13.0412 12.1433 13.0412 13.2276 12.443 13.8974L10.312 16.2873C9.7137 16.957 8.74507 16.957 8.1468 16.2873C7.6169 15.6941 7.54853 14.7606 7.98346 14.0803L8.00435 14.0462C8.19998 13.7401 8.1354 13.3148 7.86381 13.0979C7.59221 12.8811 7.21046 12.9512 7.01673 13.2553L6.99584 13.2893C6.21334 14.5097 6.33679 16.1852 7.28643 17.2483C8.35952 18.4496 10.0974 18.4496 11.1704 17.2483L13.3033 14.8606ZM3.4347 14.363C2.36161 15.5644 2.36161 17.5099 3.4347 18.7112C4.38434 19.7743 5.88096 19.9125 6.97305 19.0386L7.00344 19.0152C7.27693 18.7962 7.33961 18.371 7.14398 18.0669C6.94836 17.7629 6.5685 17.6906 6.29691 17.9096L6.26652 17.933C5.65685 18.4199 4.82307 18.3433 4.29507 17.7501C3.6968 17.0782 3.6968 15.9939 4.29507 15.3241L6.42606 12.9363C7.02433 12.2666 7.99296 12.2666 8.59123 12.9363C9.12113 13.5296 9.1895 14.463 8.75457 15.1455L8.73367 15.1795C8.53805 15.4857 8.60262 15.9109 8.87422 16.1278C9.14582 16.3447 9.52757 16.2745 9.7213 15.9705L9.74219 15.9364C10.5247 14.7139 10.4012 13.0384 9.4516 11.9753C8.37851 10.774 6.64067 10.774 5.56758 11.9753L3.4347 14.363Z" fill="#9000F5" />
                </svg>
              </SvgIcon>

              <h4>Get Started with a Wallet</h4>
              <p>Connect to imagiNation by logging in with a supported Wallet. Start by clicking the Wallet Icon found in the top right corner.</p>
            </div>

            <div className='single-work'>
              <i className='icon icon-grid' />
              <h4>Create a Unique Collection</h4>
              <p>Click your new Profile Button and tap the <strong><NavLink to='/create' className='create btn btn-text'>
                <i className='fa-solid fa-plus-square fa-xl' />
                <span className="sr-only">Create</span>
              </NavLink></strong> to start creating today! Customize your collection and set secondary sales fees.</p>
            </div>

            <div className='single-work'>
              <i className='icon icon-drawer' />
              <h4>Bring your Assets to Life</h4>
              <p>Upload any form of creativity to imagiNation. Title it, describe it, and customize it with unique properties to unlock more potential.</p>
            </div>

            <div className='single-work'>
              <i className='icon icon-bag' />
              <h4>List your Assets for Sale</h4>
              <p>Monetize your creativity by listing your Digital Assets for sale at a fixed price or ignite the competitive spirit by starting an auction.</p>
            </div>
          </div> {/* end items */}

          <p className='text-center'><em>Each Digital Asset you create is more than just an NFT or DOB; it's a piece of your imagination brought to life on the blockchain.</em></p>
        </div>
      </article>
    )
  }
}

export default Work
