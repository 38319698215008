import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import AuthorAvatar from './AuthorAvatar'
import MediaWrapper from '../Media/MediaWrapper'

const AuthorItem = (props) => {
  const { author } = props

  return (
    <>
      {author &&
        <div className='author'>
          <div className='card text-center has-author'>
            <div className='image-over'>
              <Link to={`/account/${author.address}`} className='image-link'>
                <MediaWrapper src={author.profileCover} alt={`${author.name}: ${author.bio}`}  style={{ objectFit: 'cover', minHeight: '225px' }} />
              </Link>
            </div>

            <AuthorAvatar author={author} hasFrame={true} displayName={false} className='center' />

            <div className='card-body'>
              <Link to={`/account/${author.address}`}>
                <h5>{author.name}</h5>
              </Link>
            </div>
          </div>
        </div>}
    </>
  )
}

export default AuthorItem
