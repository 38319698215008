"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapDobTraits = mapDobTraits;
const UNICORN_DOB_TRAIT_DETAILS = {
    'Socrates Tail': 'Gives Unicorns more philosophical temperament and wisdom, and such Unicorns are more inclined to contemplate the world.',
    'Dumbledore Tail': 'Gives Unicorns a higher understanding of magic and the role it should play, and such Unicorns are more inclined to expand the magical world.',
    'Venus Tail': 'Gives Unicorns more understanding of beauty and love.',
    'Gaia Tail': 'Gives Unicorns a tendency to be tolerant and love life.',
    'Rainbow Tail': 'Gives Unicorns a vibrant and colorful essence.',
};
const UNICORN_WING_TRAIT_DETAILS = {
    'Wind Wings': 'The best choice for Yang wood',
    'Night Shadow Wings': 'Best choice for yin wood',
    'Sun Wings': 'The best choice for Yang Fire',
    'Lightning Wings': 'Best choice for Yin Fire',
    'Golden Wings': 'The best choice for Yang Jin',
    'Star Wings': 'The best choice for Yin Metal',
    'Cloud Wings': 'Best choice for Yintu',
    'Morning Glow Wings': 'Best choice for Yang soil',
    'Spring Wings': 'The best choice for Yang Shui',
    'Moon Wings': 'Best choice for Yin water',
    'Angel Wings': 'Wings of Freedom! Best choice of all Unicorns',
};
function mapDobTraits(traits) {
    const result = {};
    traits.forEach(trait => {
        const { name, traits: traitArray } = trait;
        if (!(traitArray === null || traitArray === void 0 ? void 0 : traitArray[0])) {
            console.warn(`Missing trait data for ${name}`);
            return;
        }
        const traitObj = traitArray[0];
        const traitKey = Object.keys(traitObj)[0];
        const traitValue = traitObj[traitKey];
        if (typeof traitValue !== 'string' && typeof traitValue !== 'number') {
            console.warn(`Invalid trait value type for ${name}`);
            return;
        }
        // Handle wuxing_yinyang specific logic
        if (name.includes('wuxing_yinyang')) {
            const matches = String(traitValue).match(/(\d+)<_>/);
            if (matches) {
                result[name] = parseInt(matches[1], 10);
            }
            return;
        }
        // Handle traits that reference wuxing_yinyang
        if (typeof traitValue === 'string' && traitValue.includes('(%wuxing_yinyang):')) {
            try {
                const matches = traitValue.match(/\((%wuxing_yinyang)\):\s*\[(.*?)\]/);
                if (matches) {
                    const valuesString = matches[2].replace(/'/g, '"').trim();
                    const valuesArray = JSON.parse(`[${valuesString}]`);
                    if (!result.hasOwnProperty('wuxing_yinyang')) {
                        console.warn('wuxing_yinyang value not found for reference');
                        return;
                    }
                    const index = result['wuxing_yinyang'] % valuesArray.length;
                    result[name] = valuesArray[index];
                }
            }
            catch (error) {
                console.error(`Error parsing wuxing_yinyang reference for ${name}:`, error);
                result[name] = traitValue;
            }
        }
        else {
            // Handle all other traits directly
            result[name] = traitValue;
        }
        // Add descriptions for Unicorn-specific traits (Wings and Tails)
        if (name === 'Wings' && result[name] && UNICORN_WING_TRAIT_DETAILS[result[name]]) {
            result['Wing Description'] = UNICORN_WING_TRAIT_DETAILS[result[name]];
        }
        if (name === 'Tails' && result[name] && UNICORN_DOB_TRAIT_DETAILS[result[name]]) {
            result['Tail Description'] = UNICORN_DOB_TRAIT_DETAILS[result[name]];
        }
    });
    return result;
}
