import type { LiveCell } from '@ckb-lumos/base'
import type { Hexadecimal, HexNumber, Input, OutPoint, Output, Script } from '@ckb-lumos/lumos'

import axios from 'axios'
import { Indexer } from '@ckb-lumos/lumos'
import { MIN_CKB_FEE } from '../constants'

import {
  CollectUdtResult,
  IndexerCapacity,
  IndexerException,
  leToU128,
  toCamelcase,
  UdtAmountNotEnoughException
} from '@nervina-labs/ckb-dex'
import CkbController from './CkbController'

interface IndexerCell {
  blockNumber: HexNumber
  outPoint: OutPoint
  output: Output
  outputData: Hexadecimal
  txIndex: HexNumber
}
interface CollectResult {
  inputs: Input[]
  capacity: bigint
}

export class Collector {
  private ckbNodeUrl
  private ckbIndexerUrl

  constructor({ ckbNodeUrl, ckbIndexerUrl }: { [key: string]: string }) {
    this.ckbNodeUrl = ckbNodeUrl;
    this.ckbIndexerUrl = ckbIndexerUrl
  }
  getCkb(): Indexer {
    return new Indexer(this.ckbIndexerUrl)
  }
  async getCells({ lock, type }: {
    lock?: Script
    type?: Script
  }): Promise<IndexerCell[] | undefined> {
    // return __awaiter(this, void 0, void 0, async () => {
    let param
    if (lock) {
      const filter = type
        ? {
          script: {
            code_hash: type.codeHash,
            hash_type: type.hashType,
            args: type.args,
          },
        }
        : {
          script: null,
          output_data_len_range: ['0x0', '0x1'],
        }
      param = {
        script: {
          code_hash: lock.codeHash,
          hash_type: lock.hashType,
          args: lock.args,
        },
        script_type: 'lock',
        script_search_mode: 'exact',
        filter,
      }
    }
    else if (type) {
      param = {
        script: {
          code_hash: type.codeHash,
          hash_type: type.hashType,
          args: type.args,
        },
        script_search_mode: 'exact',
        script_type: 'type',
      }
    }
    let payload = {
      id: 1,
      jsonrpc: '2.0',
      method: 'get_cells',
      params: [param, 'asc', '0x3E8'],
    }
    const body = JSON.stringify(payload, null, '  ')
    let response = (await axios({
      method: 'post',
      url: this.ckbIndexerUrl,
      headers: {
        'Content-Type': 'application/json',
      },
      timeout: 20000,
      data: body,
    })).data
    if (response.error) {
      console.error(response.error)
      throw new Error('Get cells error')
    }
    else {
      return toCamelcase(response.result.objects)
    }
    // })
  }
  async getCapacity(lock: Script): Promise<IndexerCapacity | undefined> {
    // return __awaiter(this, void 0, void 0, async () => {
    let payload = {
      id: 1,
      jsonrpc: '2.0',
      method: 'get_cells_capacity',
      params: [
        {
          script: {
            code_hash: lock.codeHash,
            hash_type: lock.hashType,
            args: lock.args,
          },
          script_type: 'lock',
        },
      ],
    }
    const body = JSON.stringify(payload, null, '  ')
    let response = (await axios({
      method: 'post',
      url: this.ckbIndexerUrl,
      headers: {
        'Content-Type': 'application/json',
      },
      timeout: 20000,
      data: body,
    })).data
    if (response.error) {
      console.error(response.error)
      throw new IndexerException('Get cells capacity error')
    }
    else {
      return toCamelcase(response.result)
    }
    // })
  }
  collectInputs(liveCells: IndexerCell[], needCapacity: bigint, fee: bigint, minCapacity?: bigint, errMsg?: string): CollectResult {
    const changeCapacity = minCapacity !== null && minCapacity !== void 0 ? minCapacity : BigInt(MIN_CKB_FEE * 10 ** 8)
    let inputs = []
    let sum = BigInt(0)
    for (let cell of liveCells) {
      inputs.push({
        previousOutput: {
          txHash: cell.outPoint.txHash,
          index: cell.outPoint.index,
        },
        since: '0x0',
      })
      sum = sum + BigInt(cell.output.capacity)
      if (sum >= needCapacity + changeCapacity + fee) {
        break
      }
    }
    if (sum < needCapacity + fee) {
      throw new Error('Capacity not enough')
    }
    if (sum < needCapacity + changeCapacity + fee) {
      throw new Error('Capacity not enough for change')
    }
    return { inputs, capacity: sum }
  }
  collectUdtInputs(liveCells: IndexerCell[], needAmount: bigint): CollectUdtResult {
    let inputs = []
    let sumCapacity = BigInt(0)
    let sumAmount = BigInt(0)
    for (let cell of liveCells) {
      inputs.push({
        previousOutput: {
          txHash: cell.outPoint.txHash,
          index: cell.outPoint.index,
        },
        since: '0x0',
      })
      sumCapacity = sumCapacity + BigInt(cell.output.capacity)
      sumAmount += leToU128(cell.outputData)
      if (sumAmount >= needAmount) {
        break
      }
    }
    if (sumAmount < needAmount) {
      throw new UdtAmountNotEnoughException('Insufficient UDT balance')
    }
    return { inputs, capacity: sumCapacity, amount: sumAmount }
  }
  async getLiveCell(outPoint: OutPoint): Promise<LiveCell | null> {
    // return __awaiter(this, void 0, void 0, function* () {
    const rpc = CkbController.rpc
    const { cell } = await rpc.getLiveCell(outPoint, true)
    return cell
    // })
  }
}
