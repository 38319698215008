import axios from 'axios'
import { FileObject, NETWORK, PINATA_JWT_TOKEN } from '../constants'

export const getIpfsHashFromJson = async (data: IpfsObject) => {
  try {
    const res = await axios.post(
      'https://api.pinata.cloud/pinning/pinJSONToIPFS',
      JSON.stringify(data),
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${PINATA_JWT_TOKEN}`
        }
      }
    )
    return res.data.IpfsHash
  } catch (error) {
    throw new Error('Error getting IPFS Hash from JSON')
  }
}

export const getIpfsHashFromFile = async (
  fileObject: FileObject,
  fileInfo?: {
    name?: string,
    keyvalues?: object
  }
) => {
  try {
    if (!fileObject || !fileObject?.buffer) throw new Error('No file for IPFS upload!')

    const formData = new FormData()
    const file = new File([
      new Blob([fileObject.buffer], { type: fileObject.mimeType })
    ], fileObject?.name ?? `${NETWORK} - imagiNation Pin`
    )
    formData.append('file', file)
    formData.append('pinataOptions', JSON.stringify({ groupId: NETWORK === 'mainnet' ? '96535979-a5ec-46a2-aef1-d4aabd24c99b' : '90c1a3e7-e894-4bde-a0b0-5ae22792be32' }))

    if (!fileInfo && fileObject?.name) {
      fileInfo = { name: fileObject.name }
    }

    if (fileInfo) {
      formData.append('pinataMetadata', JSON.stringify(fileInfo))
    }

    const config = {
      maxBodyLength: Infinity,
      headers: {
        // @ts-ignore _boundary
        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
        Authorization: `Bearer ${PINATA_JWT_TOKEN}`
      }
    }
    const res = await axios.post('https://api.pinata.cloud/pinning/pinFileToIPFS', formData, config)
    return res.data.IpfsHash
  } catch (error) {
    throw new Error('Error getting IPFS Hash from File')
  }
}
