"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getStorageTypeByStyle = void 0;
exports.getStorageStyleByType = getStorageStyleByType;
const constants_1 = require("../constants");
function getStorageStyleByType(type) {
    switch (type) {
        case constants_1.StorageType.IPFS:
            return [
                constants_1.StorageStyle.IPFSCompact,
                constants_1.StorageStyle.IPFSContent,
                constants_1.StorageStyle.IPFSObject,
            ];
        case constants_1.StorageType.CKBFS:
            return [
                constants_1.StorageStyle.CKBFSCompact,
                constants_1.StorageStyle.CKBFSContent,
                constants_1.StorageStyle.CKBFSObject,
            ];
        case constants_1.StorageType.CellData:
            return [
                constants_1.StorageStyle.CellDataNative,
                // StorageStyle.CellDataObject,
            ];
        // case StorageType.BTCFS:
        //   return null
        default:
            return [];
    }
}
const getStorageTypeByStyle = (style) => {
    switch (style) {
        case constants_1.StorageStyle.CellDataNative:
        case constants_1.StorageStyle.CellDataObject:
            return constants_1.StorageType.CellData;
        case constants_1.StorageStyle.CKBFSCompact:
        case constants_1.StorageStyle.CKBFSContent:
        case constants_1.StorageStyle.CKBFSObject:
            return constants_1.StorageType.CKBFS;
        case constants_1.StorageStyle.IPFSCompact:
        case constants_1.StorageStyle.IPFSContent:
        case constants_1.StorageStyle.IPFSObject:
        default:
            return constants_1.StorageType.IPFS;
    }
};
exports.getStorageTypeByStyle = getStorageTypeByStyle;
// export const getDataPlaceholderHex = (style: StorageStyle, hasCluster: boolean): HexString => {
//   let data = '0x'
//   switch (style) {
//     case StorageStyle.CKBFSCompact:
//     case StorageStyle.IPFSCompact:
//       data = bytes.hexify(packRawSporeData({
//         contentType: 'ckbfs/image;ipfs='.concat('A'.repeat(46)),
//         content: bytes.hexify(new TextEncoder().encode('ipfs://'.concat('A'.repeat(46)))),
//         clusterId: hasCluster ? '0x'.concat('A'.repeat(64)) : undefined,
//       }))
//       break
//   }
//   return data
// }
