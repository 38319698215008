import React, { useState } from 'react'
import GridIcon from '@mui/icons-material/GridOnRounded'
import ListIcon from '@mui/icons-material/FormatListBulleted'
import { SvgIcon } from '@mui/material'

const SearchTopbar = (props: { [key: string]: any }) => {
  const { onFormChange, formValues } = props

  return (
    <div id="SearchTopbar">
      <span className='form-group'>
        <label htmlFor="pageLimit">Page Limit</label>
        <select id="pageLimit" name="pageLimit" value={formValues?.pageLimit} onChange={(event) => onFormChange('pageLimit', event.target.value)} title='Page Limit'>
          <option value="6">6</option>
          <option value="12">12</option>
          <option value="24">24</option>
          <option value="48">48</option>
          <option value="102">102</option>
        </select>
      </span>

      <span className='form-group'>
        <label htmlFor="sortBy">Sort By</label>
        <select id="sortBy" name="sortBy" value={formValues?.sortBy} onChange={(event) => onFormChange('sortBy', event.target.value)} title='Sort By'>
          <option value="isFeatured">Featured</option>
          <option value="updatedAt">Last Updated</option>
          <option value="createdAt">Mint Date</option>
          <option value="price">Price</option>
          <option value="type">Contract Standard</option>
          <option value="royalty">Royalty</option>
          {/* <!-- Add other relevant sorting options --> */}
        </select>
      </span>

      <span className='form-group'>
        <label htmlFor="sortDirection">Order</label>
        <span className="radio-wrapper">
          <input type='radio' name='sortDirection' value="ascending" checked={formValues?.sortDirection === 'ascending'} onChange={(event) => onFormChange('sortDirection', event.target.value)} title='Sort Order Ascending' />

          {/* <ArrowUp className='icon' sx={{ fontSize: '1.8rem' }} /> */}
          <SvgIcon>
            {formValues?.sortDirection === 'ascending' ? <svg width="24" height="14" viewBox="0 0 24 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13.8672 0.737305C12.9688 -0.166016 11.5097 -0.166016 10.6113 0.737305L1.41127 9.9873C0.750021 10.6521 0.555959 11.6422 0.915334 12.5094C1.27471 13.3766 2.10846 13.9402 3.04283 13.9402H21.4428C22.37 13.9402 23.211 13.3766 23.5703 12.5094C23.9297 11.6422 23.7285 10.6521 23.0744 9.9873L13.8744 0.737305H13.8672Z" fill="#025C00" />
            </svg>
              : <svg width="24" height="14" viewBox="0 0 24 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.5127 1.0899L13.6593 1.2373H13.6665L22.718 10.338C22.7183 10.3383 22.7186 10.3386 22.7189 10.3389C23.2336 10.8628 23.3896 11.6395 23.1084 12.318C22.8256 13.0003 22.1657 13.4402 21.4428 13.4402H3.04283C2.31369 13.4402 1.66037 13.0012 1.37724 12.318C1.09483 11.6365 1.24733 10.8612 1.76578 10.3399L10.9658 1.0899C11.6687 0.38312 12.8097 0.38312 13.5127 1.0899Z" stroke="#025C00" />
              </svg>
            }
          </SvgIcon>
        </span>

        <span className="radio-wrapper">
          <input type='radio' name='sortDirection' value="descending" checked={formValues?.sortDirection === 'descending'} onChange={(event) => onFormChange('sortDirection', event.target.value)} title='Sort Order Descending' />

          {/* <ArrowDownFilled className='icon' sx={{ fontSize: '1.8rem' }} /> */}
          <SvgIcon>
            {formValues?.sortDirection === 'descending' ? <svg width="24" height="14" viewBox="0 0 24 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13.8768 13.2627C12.9784 14.166 11.5193 14.166 10.6209 13.2627L1.42085 4.0127C0.759604 3.34785 0.565541 2.35781 0.924916 1.49063C1.28429 0.623438 2.11804 0.0597647 3.05242 0.0597647H21.4524C22.3796 0.0597647 23.2205 0.623438 23.5799 1.49063C23.9393 2.35781 23.738 3.34785 23.084 4.0127L13.884 13.2627H13.8768Z" fill="#025C00" />
            </svg>
              : <svg width="24" height="14" viewBox="0 0 24 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.5223 12.9101L13.6689 12.7627H13.6761L22.7275 3.66204C22.7279 3.66171 22.7282 3.66138 22.7285 3.66105C23.2431 3.13718 23.3992 2.36054 23.118 1.68205C22.8352 0.999678 22.1753 0.559765 21.4524 0.559765H3.05242C2.32327 0.559765 1.66995 0.998841 1.38682 1.68205C1.10441 2.36351 1.25692 3.13884 1.77536 3.6601L10.9754 12.9101C11.6783 13.6169 12.8193 13.6169 13.5223 12.9101Z" stroke="#025C00" />
              </svg>
            }
          </SvgIcon>
        </span>
      </span>

      <span className='form-group'>
        <label htmlFor='display'>Display</label>

        <span className="radio-wrapper">
          <input type='radio' name='display' value="grid" checked={formValues?.display === 'grid'} onChange={(event) => onFormChange('display', event.target.value)} title='Grid View' />

          <GridIcon className='icon' sx={{ fontSize: '1.8rem' }} />
        </span>

        <span className="radio-wrapper disabled">
          <input type='radio' name='display' value="list" checked={formValues?.display === 'list'} onChange={(event) => onFormChange('display', event.target.value)} title='List View' />

          <ListIcon className='icon' sx={{ fontSize: '1.8rem' }} />
        </span>

        {/* <select id="display" name="display" value={formValues?.display} >
          <option >Grid View</option>
          <option >List View</option>
        </select> */}
      </span>
    </div>
  )
}

export default SearchTopbar
