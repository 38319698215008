import { Link } from 'react-router-dom'
import HighlightIcon from '@mui/icons-material/Highlight'

import NervapeGearSpotlight from '../../assets/img/spotlight/nervape-gear-nervos-ckb-layer-1.webp'
import NervapeGearSpotlightMobile from '../../assets/img/spotlight/nervape-gear-nervos-ckb-layer-1-mobile.webp'

import DragonEggBanner from '../../assets/img/spotlight/world3-dragon-eggs-on-imagination-market.webp'
import DragonEggBannerMobile from '../../assets/img/spotlight/world3-dragon-eggs-on-imagination-market-mobile.webp'

import BeafVip from '../../assets/img/spotlight/beaf-vip-dob-nft-banner.png'
import BeafVipMobile from '../../assets/img/spotlight/beaf-vip-dob-nft-mobile.jpg'

const HeroSpotlight = () => {
  // Add your component logic here

  return (
    <div id="Spotlight">
      <h2><HighlightIcon /><span>Spotlight</span><HighlightIcon /></h2>
      <div className="content">
        <div id="Spotlight--Carousel" className="carousel slide" data-ride="carousel" data-interval="15000" data-pause="hover">
          <ol className="carousel-indicators">
            <li data-target="#Spotlight--Carousel" data-slide-to="0" className="active"></li>
            <li data-target="#Spotlight--Carousel" data-slide-to="1"></li>
            <li data-target="#Spotlight--Carousel" data-slide-to="2"></li>
            <li data-target="#Spotlight--Carousel" data-slide-to="3"></li>
          </ol>
          <div className="carousel-inner">

            {/* SPOTLIGHT #1 */}
            <div className="carousel-item active">
              <div className="collection">
                <span className="image-wrapper full">
                  <img
                    src={BeafVip}
                    className='d-none d-md-inline'
                    alt="$BEAF VIP Cards - Access to all rewards $BEAF" title="$BEAF VIP Cards - Access to all rewards $BEAF" loading="lazy" crossOrigin="anonymous" />
                  <img
                    src={BeafVipMobile}
                    className='d-inline d-md-none'
                    alt="$BEAF VIP Cards - Access to all rewards $BEAF" title="$BEAF VIP Cards - Access to all rewards $BEAF" loading="lazy" crossOrigin="anonymous" />
                </span>

                <h4 className="title sr-only" title="$BEAF VIP Cards - Access to all rewards $BEAF">$BEAF</h4>

                <Link
                  to="/collection/0x14585f4d84a5ed0c4e4be0fcec96f6fed93a8191c27b27197a71934e97364c96"
                  className='btn btn-block btn-warn mb-0'
                  style={{ fontWeight: 'bold' }}
                >Become a VIP!</Link>
              </div>
            </div>
            {/* END SPOTLIGHT #1 */}

            {/* SPOTLIGHT #2 */}
            <div className="carousel-item">
              <div className="collection">
                <span className="image-wrapper full">
                  <img
                    src={NervapeGearSpotlight}
                    className='d-none d-md-inline'
                    alt="Nervape Gear on Nervos CKB Layer 1, now on imagiNation Market" title="Nervape Gear on Nervos CKB Layer 1, now on imagiNation Market" loading="lazy" crossOrigin="anonymous" />
                  <img
                    src={NervapeGearSpotlightMobile}
                    className='d-inline d-md-none'
                    alt="Nervape Gear on Nervos CKB Layer 1, now on imagiNation Market" title="Nervape Gear on Nervos CKB Layer 1, now on imagiNation Market" loading="lazy" crossOrigin="anonymous" />
                </span>

                <h4 className="title sr-only" title="Nervape Gear">Nervape Gear</h4>

                <Link
                  to="/collection/nervape-gear"
                  className='btn btn-block btn-success'
                  style={{ fontWeight: 'bold' }}
                >Explore Nervape Gear!</Link>
              </div>
            </div>
            {/* END SPOTLIGHT #2 */}

            {/* SPOTLIGHT #3 */}
            <div className="carousel-item">
              <div className="collection has-content">
                <span className="image-wrapper full">
                  <img
                    src={DragonEggBanner}
                    className='d-none d-md-inline'
                    alt="World3 Dragon Eggs on imagiNation Market" title="World3 Dragon Eggs on imagiNation Market" crossOrigin="anonymous" />
                  <img
                    src={DragonEggBannerMobile}
                    className='d-inline d-md-none'
                    alt="World3 Dragon Eggs on imagiNation Market" title="World3 Dragon Eggs on imagiNation Market" crossOrigin="anonymous" />
                </span>

                <div className="content">
                  <h4 className="title" title="World3 Dragon DOB Eggs">World3 Dragon Eggs</h4>
                  <p>The <strong>next</strong> and <em>cutest</em> <u>AI-companion</u> is waiting for you!</p>
                </div>

                <Link
                  to="/collection/0xf717625c0161f88007e187743f05e2ec8493e22ccdffb1de561c42b6b5c64cf8"
                  className='btn btn-block btn-success'
                  style={{ fontWeight: 'bold' }}
                >View Eggs</Link>
              </div>
            </div>
            {/* END SPOTLIGHT #3 */}

            {/* SPOTLIGHT #4 */}
            <div className="carousel-item">
              <div className="collection nation">
                <span className="image-wrapper">
                  <img src="//ipfs.io/ipfs/QmVQM1TePXpdAiWTsRWSatdfxivCcxuysnJnfrq1vKe6VF" alt="" title="" loading="lazy" crossOrigin="anonymous" />
                </span>

                <h4 className="title" title="Final Tom-a-bot Battlers">Spore Nation</h4>

                <div className="description contained">
                  <p>A collection of Spores <em>sprouted</em> by the community. From your stick-man drawings to 3D Renderings, explore the possibilities of CKB with your <strong>imagination</strong> today!</p>

                  <Link
                    to="/collection/0x66f4718d56763e6c5a59f1dcebe46b6bf9ccbb5e57f931ce159203058cef5795"
                    className='btn btn-block btn-danger'
                  >Sprout a Spore</Link>

                  <p><small></small></p>
                </div>

                <span className="image-wrapper extra">
                  <img src="//ipfs.io/ipfs/QmQdWw27xnzhU8Pt3N6Qc7jkXLqSQRNwezbJjF5yPhhGBL" alt="" title="" loading="lazy" crossOrigin="anonymous" />
                </span>
              </div>
            </div>
            {/* END SPOTLIGHT #4 */}
          </div> {/* end carousel inner */}
        </div>
      </div>
    </div>
  );
};

export default HeroSpotlight;
