"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v;
Object.defineProperty(exports, "__esModule", { value: true });
exports.StorageType = exports.StorageStyle = exports.PROFILE_COVER_URL = exports.PROFILE_PICTURE_URL = exports.DEFAULT_USER_NAME = exports.COTA_REGISTRY_RELAY_WALLET = exports.SYMBOL_KEYS = exports.CHAIN_KEYS = exports.APP_KEYS = exports.NFT_STANDARDS = exports.ACTIVITY_CONSTANTS = exports.HAS_PRIVILEGES = exports.PINATA_JWT_TOKEN = exports.IPFS_URL = exports.NETWORK = exports.CKBFS_CODE_HASH = exports.CKB_NATION_FEE_ADDRESS = exports.COTA_REGISTRY_FEE = exports.TRANSFER_FEE = exports.MIN_CKB_FEE = exports.COLLECTION_FEE = exports.EVM_NODES = exports.EVM_EXPLORER_URL = exports.EVM_MAINNET_CHAIN_ID = exports.EVM_TESTNET_CHAIN_ID = exports.EVM_FEE = exports.ZERO_ADDRESS = exports.DEAD_ADDRESS = exports.EVM_FEE_ADDRESS = void 0;
__exportStar(require("./collection-groups"), exports);
__exportStar(require("./types"), exports);
__exportStar(require("./keys"), exports);
// EVM
// Given address associated with a multi-sig
exports.EVM_FEE_ADDRESS = '0x78c47bf6daf128a6c8e16c2dc42fe38d5a6f4421';
exports.DEAD_ADDRESS = '0x000000000000000000000000000000000000dead';
exports.ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';
exports.EVM_FEE = 10;
// Assigning to give labels to chain IDs
const GODWOKEN_TESTNET_CHAIN_ID = 71401;
const GODWOKEN_MAINNET_CHAIN_ID = 71402;
exports.EVM_TESTNET_CHAIN_ID = GODWOKEN_TESTNET_CHAIN_ID;
exports.EVM_MAINNET_CHAIN_ID = GODWOKEN_MAINNET_CHAIN_ID;
exports.EVM_EXPLORER_URL = (_b = (_a = process.env) === null || _a === void 0 ? void 0 : _a.EVM_EXPLORER_URL) !== null && _b !== void 0 ? _b : 'https://testnet.gwscan.com';
exports.EVM_NODES = [(_d = (_c = process.env) === null || _c === void 0 ? void 0 : _c.EVM_NODE_1) !== null && _d !== void 0 ? _d : '', (_f = (_e = process.env) === null || _e === void 0 ? void 0 : _e.EVM_NODE_2) !== null && _f !== void 0 ? _f : '', (_h = (_g = process.env) === null || _g === void 0 ? void 0 : _g.EVM_NODE_3) !== null && _h !== void 0 ? _h : ''];
// CKB
// Random wallets currently owned by rabbitt (testing/fallback for now)
exports.COLLECTION_FEE = 100;
exports.MIN_CKB_FEE = 63;
exports.TRANSFER_FEE = 30;
exports.COTA_REGISTRY_FEE = 155;
exports.CKB_NATION_FEE_ADDRESS = {
    mainnet: 'ckb1qrgqep8saj8agswr30pls73hra28ry8jlnlc3ejzh3dl2ju7xxpjxqgqq9055rrhjxn7a7ugpn57pd2j4gucq8vrccdy7ejh',
    testnet: 'ckt1qrfrwcdnvssswdwpn3s9v8fp87emat306ctjwsm3nmlkjg8qyza2cqgqq90785dqrsq3dvputdg3rmkx4p70yvr4acljfknd',
};
// Same CODE deployed across networks
exports.CKBFS_CODE_HASH = '0x31e6376287d223b8c0410d562fb422f04d1d617b2947596a14c3d2efb7218d3a';
// Random IDs for internal use
// Probably unused..
// export const CKB_TESTNET_CHAIN_ID = 71393
// export const CKB_MAINNET_CHAIN_ID = 71394
// export const CHAIN_NETWORK_MAP = {
//   testnet: [EVM_TESTNET_CHAIN_ID, CKB_TESTNET_CHAIN_ID],
//   mainnet: [EVM_MAINNET_CHAIN_ID, CKB_MAINNET_CHAIN_ID],
// }
// General
exports.NETWORK = (_k = (_j = process.env) === null || _j === void 0 ? void 0 : _j.NETWORK) !== null && _k !== void 0 ? _k : 'testnet';
exports.IPFS_URL = (_m = (_l = process.env) === null || _l === void 0 ? void 0 : _l.IPFS_URL) !== null && _m !== void 0 ? _m : 'ipfs://';
exports.PINATA_JWT_TOKEN = (_r = (_p = (_o = process.env) === null || _o === void 0 ? void 0 : _o.PINATA_JWT_TOKEN) !== null && _p !== void 0 ? _p : (_q = process.env) === null || _q === void 0 ? void 0 : _q.REACT_APP_PINATA_JWT_TOKEN) !== null && _r !== void 0 ? _r : '';
// @type {string[]}
exports.HAS_PRIVILEGES = ((_s = process.env) === null || _s === void 0 ? void 0 : _s.DEV_ADDRESSES) ? (_t = process.env.DEV_ADDRESSES) === null || _t === void 0 ? void 0 : _t.split(',') : ((_u = process.env) === null || _u === void 0 ? void 0 : _u.REACT_APP_DEV_ADDRESSES) ? (_v = process.env.REACT_APP_DEV_ADDRESSES) === null || _v === void 0 ? void 0 : _v.split(',') : [];
exports.ACTIVITY_CONSTANTS = {
    Mint: 0,
    ListingCreated: 1,
    ListingCancelled: 2,
    ListingSold: 3,
    AuctionCreated: 4,
    AuctionCancelled: 5,
    AuctionEnded: 6,
    BidCreated: 7,
    BidReplaced: 8,
    BidWinner: 9,
    Transfer: 10,
};
exports.NFT_STANDARDS = {
    unknown: 'unknown',
    erc721: 'ERC721',
    erc1155: 'ERC1155',
    spore: 'Spore',
    cota: 'CoTA',
    blitz: 'Blitz' // Placeholder; May end up xUDT
};
exports.APP_KEYS = {
    metamask: 'Metamask',
    joyid: 'JoyID',
    ckbull: 'CKBull',
    lumos: 'Lumos',
};
exports.CHAIN_KEYS = {
    ckb: 'CKB',
    godwoken: 'Godwoken',
};
exports.SYMBOL_KEYS = {
    ckb: 'CKB',
    godwoken: 'pCKB',
};
// export const COTA_REGISTRY_RELAY_WALLET = 'ckt1qrejnmlar3r452tcg57gvq8patctcgy8acync0hxfnyka35ywafvkqgptuqyjwwjgufsazxqsupunwxr4h5cylqgqqjmnegx' // Lumos Omni_LockV2 generated Address
exports.COTA_REGISTRY_RELAY_WALLET = exports.NETWORK === 'mainnet' ? 'ckb1qrejnmlar3r452tcg57gvq8patctcgy8acync0hxfnyka35ywafvkqgqp0zvvvqastqtlqdgxlphldxp62xmmurvqqfheam0' : 'ckt1qrejnmlar3r452tcg57gvq8patctcgy8acync0hxfnyka35ywafvkqgqp0zvvvqastqtlqdgxlphldxp62xmmurvqqjqd368'; // pubkeyhash generated relay lock
exports.DEFAULT_USER_NAME = 'imagiNation User';
exports.PROFILE_PICTURE_URL = 'https://ipfs.io/ipfs/QmaxQGhY772ffG7dZpGsVoUWcdSpEV1APru95icXKmii67';
exports.PROFILE_COVER_URL = 'https://ipfs.io/ipfs/QmfHZw5sZucRfZvqfPW1MBLAeHJUb5k8m8V4jjD8tbtqwX';
var StorageStyle;
(function (StorageStyle) {
    StorageStyle["CellDataNative"] = "Cell Data Native";
    StorageStyle["CellDataObject"] = "Cell Data Object";
    StorageStyle["IPFSCompact"] = "IPFS Compact";
    StorageStyle["IPFSContent"] = "IPFS Content";
    StorageStyle["IPFSObject"] = "IPFS Object";
    StorageStyle["CKBFSCompact"] = "CKBFS Compact";
    StorageStyle["CKBFSContent"] = "CKBFS Content";
    StorageStyle["CKBFSObject"] = "CKBFS Object";
})(StorageStyle || (exports.StorageStyle = StorageStyle = {}));
var StorageType;
(function (StorageType) {
    StorageType["CellData"] = "Cell Data";
    StorageType["IPFS"] = "IPFS";
    StorageType["CKBFS"] = "CKBFS";
    // BTCFS,
})(StorageType || (exports.StorageType = StorageType = {}));
