import styled from 'styled-components'
import { PlayCircleOutline } from '@styled-icons/material'
import { MusicNote } from '@styled-icons/material/MusicNote'
import { Image } from '@styled-icons/feather/Image'

export const coverStyle = {
  height: '100%',
  width: '100%',
  borderRadius: '6px',
  objectFit: 'cover',
  objectPosition: '50% 33%'
}
export const VideoIcon = styled(PlayCircleOutline)`
`
export const AudioIcon = styled(MusicNote)`
`
export const ImageIcon = styled(Image)`
`
export const typeIcon = {
  position: 'absolute',
  top: 10,
  left: 10,
  background: '#451CCEd9',
  height: 28,
  width: 28,
  textAlign: 'center',
  borderRadius: 10,
  border: '1px solid rgba(255,255,255,0.6)'
}
