import React from 'react'

import Breadcrumb from '../components/Breadcrumb/Breadcrumb'
import Auctions from '../components/Auctions/Auctions'

const AuctionsTheme = (props) => {
  return (
    <div className='main'>
      <Breadcrumb {...props} title='Live Auctions' subpage='Explore' page='Live Auctions' />
      <Auctions {...props} />
    </div>
  )
}

export default AuctionsTheme
