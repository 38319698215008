import React from 'react'

import Breadcrumb from '../components/Breadcrumb/Breadcrumb'
import AuthorGrid from '../components/Author/AuthorArea'
import TopBuyers from '../components/Stats/TopBuyers'

const AuthorsPage = (props) => {
  return (
    <>
      <Breadcrumb {...props} title='Authors' subpage='Pages' page='Authors' />
      <article>
        <AuthorGrid />
        <TopBuyers />
      </article>
    </>
  )
}

export default AuthorsPage
