import { useEffect, useMemo, useState } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import { useWeb3React } from '@web3-react/core'
import { CircularProgress, Icon, SvgIcon } from '@mui/material'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import CloseIcon from '@mui/icons-material/Close'

import { connectors } from '../../utils/connectors'
import { getTokenBalance } from '../../utils/EVM/EvmService'
import { CHAIN_KEYS } from '../../utils/constants'
import { prettyCommaFormat } from '../../utils/helpers'
import { useAuth } from '../../context/authContext'
import CkbController from '../../utils/CKB/CkbController'
import NftChainLogo from '../Item/NftChainLogo'

import './header.scss'

const UserPopover = (props: any) => {
  const { dispatch, state } = useAuth()
  const { user, provider: providerName } = state
  const { connector, provider } = useWeb3React()
  const { getBalance } = CkbController
  const history = useHistory()
  const popoverTarget = 'UserActions'

  const [unreadActivities, setUnreadActivities] = useState(0)
  const [ckbBalance, setCkbBalance] = useState('0')
  const [evmBalance, setEvmBalance] = useState('0')
  const [syncingData, setSyncingData] = useState(false)

  useEffect(() => {
    if (!user) return
    let isMounted = true

    setUnreadActivities(user?.unreadActivities?.length ?? 0)

    const headerNav = document.querySelector('#header > nav')
    const userPopoverHub = document.getElementById(popoverTarget)
    const userPopoverActions = userPopoverHub?.querySelectorAll('a, button')

    if (userPopoverHub) {
      // @ts-ignore
      document.querySelector('[data-toggle="modal"]')?.addEventListener('click', () => userPopoverHub.hidePopover())
    }

    if (userPopoverHub && headerNav) {
      if (window.innerWidth > 767) {
        userPopoverHub.style.right = `${headerNav.getBoundingClientRect().left}px`
        userPopoverHub.style.top = '85px'
      } else {
        userPopoverHub.style.right = '1rem'
        userPopoverHub.style.top = '83px'
      }

      // Gives a Fade-in effect
      userPopoverHub.addEventListener('beforetoggle', event => {
        const target = event?.target as HTMLDivElement

        if (!target) return

        target.style.display = 'block'
        userPopoverHub.style.right = `${headerNav.getBoundingClientRect().left + 14}px`

      })

      userPopoverHub.addEventListener('toggle', event => {
        const target = event?.target as HTMLDivElement

        if (!target) return

        // Part of Popover event
        // @ts-ignore
        if (event?.oldState === 'closed') {
          target.style.opacity = '1'
        } else {
          target.style.opacity = '0'
          setTimeout(() => target.style.display = 'none', 700)
        }
      })

      userPopoverActions?.forEach(action => action.addEventListener(
        'click',
        // @ts-ignore
        () => userPopoverHub.hidePopover()
      ))
    }

    async function getCkbBalance() {
      const balance = await getBalance(user?.addresses?.find((address: { chain: string }) => address?.chain === CHAIN_KEYS.ckb)?.address ?? '')

      setCkbBalance(String(balance))
    }

    async function getEvmBalance() {
      if (provider) {
        const balance: bigint = await getTokenBalance(user?.addresses?.find((address: { chain: string }) => address.chain === CHAIN_KEYS.godwoken)?.address, provider)

        const splitBalance = String(prettyCommaFormat(balance, 18)).split('.')

        let displayBalance = String(prettyCommaFormat(balance, 18))
        if (splitBalance[1] && splitBalance[1].length > 8) {
          splitBalance[1] = splitBalance[1].substring(0, 8)
          displayBalance = splitBalance.join('.')
        }

        setEvmBalance(displayBalance)
      }
    }

    if (isMounted) {
      getCkbBalance()
      getEvmBalance()
    }

    return () => {
      isMounted = false
    }
  }, [user, provider, getBalance, providerName, connector, state])

  const renderIcon = useMemo(() => {
    if (!providerName) return

    const currentConnector = connectors.find(connector => connector.key === providerName)

    if (!currentConnector || !currentConnector?.icon) return
    const IconComponent = currentConnector.badge

    // @ts-ignore
    return <Icon className='icon' component={IconComponent} fontSize='inherit' />
  }, [providerName])

  const trimmedAddress = useMemo(() => {
    if (!user || !user?.address) return null
    const address = user.address
    const addressLength = address.length

    return `${address.slice(0, 6)}..${address.slice(addressLength - 6)}`
  }, [user])

  // @TODO: Setup "Loading" for when User Syncing
  // const doneSyncCallback = () => {
  //   setSyncingData(false)
  // }

  function connect() {
    // setSyncingData(true)
    props.connectAccount()
  }

  function disconnect() {
    if (connector.deactivate) connector.deactivate()
    dispatch({ type: 'LOGOUT', payload: null })

    history.go(0)
  }

  return user && user?.address
    ? <div id='UserPopover' className={`${unreadActivities > 0 ? 'has-unread' : ''}`}>
      {/* @ts-ignore */}
      <button className='toggle' popovertarget={popoverTarget} popovertargetaction='toggle'>
        {renderIcon} {syncingData ? <CircularProgress style={{ width: '16px', height: '16px', color: 'white' }} /> : <span className="address">{trimmedAddress}</span>}
      </button>

      {/* @ts-ignore */}
      <div id={popoverTarget} popover='manual'>
        <div className="title">
          <h3>Wallet</h3>
          <CopyToClipboard
            text={user.address}
            onCopy={() => {
              console.info('copied address', user.address)

              const alertBar = document.querySelector('#AlertBar')
              if (!alertBar) return

              const alertBarText = alertBar.querySelector('.text')

              if (alertBarText) alertBarText.textContent = 'Address Copied!';
              alertBar.classList.add('show', 'alert-success')

              setTimeout(() => {
                if (alertBarText) alertBarText.textContent = ''
                alertBar.classList.remove('show', 'alert-success')
              }, 5000)
            }}
            children={<button className="copy" title="Copy CKB Address to Clipboard"><i className='icon-docs' /></button>}
          />
          <hr />
        </div>


        <div className="balances">
          <span>{ckbBalance} <strong>CKB</strong> <NftChainLogo standard='CKB' title='CKB is Nervos Network Layer 1s Native asset' /></span>
          <span>{evmBalance} <strong>pCKB</strong> <NftChainLogo standard='Godwoken' title='pCKB is Godwokens Native Asset, Layer 2 to CKB' /></span>
        </div>

        <hr />

        <div className="buy">
          <a
            className='btn branded btn-block p-2'
            href='https://global-stg.transak.com/?apiKey=fc46befe-6de5-4563-8ae2-d91bcf911112&defaultFiatCurrency=USD&defaultFiatAmount=50&network=nervos&walletAddress=ckb1qzda0cr08m85hc8jlnfp3zer7xulejywt49kt2rr0vthywaa50xwsqdshlv4s0hzmff2tfvl5knzv54mutygnxs4wgtlp&redirectURL=https://imagination.to&themeColor=00CC9B'
            target='_blank' rel="noopener">
            Buy CKB
          </a>
        </div>

        <hr />

        <div className="actions">
          <div className="top">
            <NavLink to='/create' className='create'>
              <i className='fa-solid fa-plus-square fa-2xl' />
              <span className="sr-only">Create</span>
            </NavLink>
            <NavLink to={`/account/${user.address}`} className='account'>
              <i className='fa-solid fa-user fa-2xl' />
              <span className="sr-only">Account</span>
            </NavLink>
            <NavLink to={`/account/${user.address}/notifications`} className='notifications'>
              <i className='fa-solid fa-envelope fa-2xl' />
              <span className="sr-only">Notifications</span>{unreadActivities > 0 && <span className='unread'>{unreadActivities}</span>}
            </NavLink>
          </div>

          <div className="bottom">
            <NavLink
              className='btn btn-primary btn-smaller update'
              to='/update-account'
            >
              Update Profile
            </NavLink>

            <button className='btn btn-danger btn-smaller logout' onClick={() => disconnect()}>
              Logout
            </button>
          </div>
        </div>
      </div>
    </div>
    : <button className='nav-item btn connect' onClick={() => connect()}>
      <SvgIcon sx={{ fontSize: 40 }}>
        <svg viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3 0C1.34531 0 0 1.34531 0 3V18C0 19.6547 1.34531 21 3 21H21C22.6547 21 24 19.6547 24 18V7.5C24 5.84531 22.6547 4.5 21 4.5H3.75C3.3375 4.5 3 4.1625 3 3.75C3 3.3375 3.3375 3 3.75 3H21C21.8297 3 22.5 2.32969 22.5 1.5C22.5 0.670312 21.8297 0 21 0H3ZM19.5 11.25C19.8978 11.25 20.2794 11.408 20.5607 11.6893C20.842 11.9706 21 12.3522 21 12.75C21 13.1478 20.842 13.5294 20.5607 13.8107C20.2794 14.092 19.8978 14.25 19.5 14.25C19.1022 14.25 18.7206 14.092 18.4393 13.8107C18.158 13.5294 18 13.1478 18 12.75C18 12.3522 18.158 11.9706 18.4393 11.6893C18.7206 11.408 19.1022 11.25 19.5 11.25Z" fill="white" />
          <path d="M13.3033 13.003C14.3764 11.9519 14.3764 10.2495 13.3033 9.1984C12.3537 8.26817 10.8571 8.14724 9.76498 8.91189L9.73459 8.93235C9.4611 9.12398 9.39842 9.49607 9.59404 9.76211C9.78967 10.0282 10.1695 10.0914 10.4411 9.89978L10.4715 9.87932C11.0812 9.45328 11.915 9.52025 12.443 10.0393C13.0412 10.6254 13.0412 11.5742 12.443 12.1602L10.312 14.2514C9.7137 14.8374 8.74507 14.8374 8.1468 14.2514C7.6169 13.7323 7.54853 12.9156 7.98346 12.3202L8.00435 12.2905C8.19998 12.0226 8.1354 11.6505 7.86381 11.4607C7.59221 11.2709 7.21046 11.3323 7.01673 11.5984L6.99584 11.6281C6.21334 12.696 6.33679 14.1621 7.28643 15.0923C8.35952 16.1434 10.0974 16.1434 11.1704 15.0923L13.3033 13.003ZM3.4347 12.5677C2.36161 13.6188 2.36161 15.3211 3.4347 16.3723C4.38434 17.3025 5.88096 17.4234 6.97305 16.6588L7.00344 16.6383C7.27693 16.4467 7.33961 16.0746 7.14398 15.8086C6.94836 15.5425 6.5685 15.4793 6.29691 15.6709L6.26652 15.6914C5.65685 16.1174 4.82307 16.0504 4.29507 15.5314C3.6968 14.9435 3.6968 13.9946 4.29507 13.4086L6.42606 11.3193C7.02433 10.7333 7.99296 10.7333 8.59123 11.3193C9.12113 11.8384 9.1895 12.6551 8.75457 13.2523L8.73367 13.2821C8.53805 13.55 8.60262 13.9221 8.87422 14.1118C9.14582 14.3016 9.52757 14.2402 9.7213 13.9742L9.74219 13.9444C10.5247 12.8746 10.4012 11.4086 9.4516 10.4784C8.37851 9.42723 6.64067 9.42723 5.56758 10.4784L3.4347 12.5677Z" fill="#9000F5" />
        </svg>
      </SvgIcon>
      <span className="sr-only">Wallet Connect</span>
    </button>
}

export default UserPopover
